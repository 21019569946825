import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="flex justify-center items-center px-16 pt-10 bg-stone-50 max-md:px-5 relative">
            <div className="max-w-full w-[1200px] flex items-center justify-between">
                <Link to="/">
                    <img src="/litfax-logo.png" alt="Logo" className="align-left" width={141} height={50} />
                </Link>
                
                {/* Desktop Navigation */}
                <nav className="hidden md:flex items-center space-x-4 h-14">
                    <a href="#details03" className="text-blue-600 border-r border-blue-600 pr-4 text-xl">Demo Profil</a>
                    <a href="#slider04" className="text-blue-600 border-r border-blue-600 pr-4 text-xl">So funktioniert's</a>
                    <a href="#benefits07" className="text-blue-600 border-r border-blue-600 pr-4 text-xl">Vorteile</a>
                    <a href="#example08" className="text-blue-600 border-r border-blue-600 pr-4 text-xl">Materialien</a>
                    <a href="#costoverview11" className="text-blue-600 pr-4 text-xl">Preise</a>
                    <a href="https://my-litfax.de/">
                        <img src="/shop.png" alt="Shop" width={141} height={70} />
                    </a>
                </nav>

                {/* Hamburger Icon for Mobile */}
                <div className="md:hidden flex items-center z-50"> {/* Ensure this button is on top */}
                    <button onClick={toggleMenu} className="text-blue-600 focus:outline-none">
                        {isOpen ? (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                            </svg>
                        )}
                    </button>
                </div>
            </div>

            {/* Mobile Navigation */}
            {isOpen && (
                <nav className="absolute top-full left-0 w-full bg-stone-50 shadow-lg md:hidden z-40"> {/* Ensure this menu is above other content */}
                    <ul className="flex flex-col space-y-2 py-4 pl-6">
                        <li><a href="#details03" className="text-blue-600 text-xl">Demo Profil</a></li>
                        <li><a href="#slider04" className="text-blue-600 text-xl">So funktioniert's</a></li>
                        <li><a href="#benefits07" className="text-blue-600 text-xl">Vorteile</a></li>
                        <li><a href="#example08" className="text-blue-600 text-xl">Materialien</a></li>
                        <li><a href="#costoverview11" className="text-blue-600 text-xl">Preise</a></li>
                        <li><a href="https://my-litfax.de/">
                            <img src="/shop.png" alt="Shop" width={141} height={70} />
                        </a></li>
                    </ul>
                </nav>
            )}
        </header>
    );
};

export default Header;
