import React from 'react';

interface TextSectionProps {
  /**
   * The main title of the text section
   */
  title: string;

  /**
   * The subtitle of the text section
   */
  subtitle: string;

  /**
   * The description text of the section
   */
  description: string;
}

/**
 * A reusable text section component with a title, subtitle, and description
 */
function TextSection({ title, subtitle, description }: TextSectionProps) {
  return (
    <section className="flex justify-center items-center px-16 pt-16 pb-3 text-center text-black bg-stone-50 max-md:px-5">
      <div className="flex flex-col max-w-full w-[1106px]">
        <h2 className="self-center text-3xl font-extralight leading-9 uppercase">
          {title}
          <br />
          <span className="font-medium uppercase">{subtitle}</span>
        </h2>
        <p className="mt-5 text-base leading-6 max-md:max-w-full">
          {description}
        </p>
      </div>
    </section>
  );
}

/**
 * The main component that renders the text section with specific content
 */
function CostOverviewHeading10() {
  return (
    <TextSection
      title="Preisüberblick"
      subtitle="INDIVIDUALANFRAGE"
      description="Im Folgenden erhalten Sie eine Preisübersicht für digitale Visitenkarten in verschiedenen Materialien wie PVC, Bambus, Metall und Papier. Alle Preise inklusive vCard-Profil je NFC-Visitenkarte oder digitaler Papiervisitenkarte, 4/0 Druck (einseitig). *zzgl. anfallender Versandkosten und ind. Zusatzleistung"
    />
  );
}

export default CostOverviewHeading10;