import React from 'react';

interface AdditionalOptionProps {
  option: string;
  price: string;
  onOptionClick: () => void;
}

const AdditionalOption: React.FC<AdditionalOptionProps> = ({
  option,
  price,
  onOptionClick,
}) => (
  <div className="flex w-full max-md:flex-col">
    <div className="flex-1 px-3 py-3.5 bg-white border-r border-b border-l border-solid border-zinc-100 leading-[129%] text-neutral-600 max-md:pr-5 max-md:w-full">
      {option}
    </div>
    <div className="flex-1 px-6 py-4 text-right bg-white border-r border-b border-solid border-zinc-100 max-md:pl-5 max-md:w-full">
      {price}
    </div>
  </div>
);

export default AdditionalOption;
