import React from 'react';

/**
 * BenefitCard component props interface
 */
interface BenefitCardProps {
  imageSrc: string;
  title: string;
  description: string;
}

/**
 * BenefitCard component
 * @param {BenefitCardProps} props - Component props
 * @returns {JSX.Element} - Rendered component
 */
const BenefitCard: React.FC<BenefitCardProps> = ({ imageSrc, title, description }) => (
  <div className="grow justify-center p-0.5 rounded border-2 border-gray-200 border-solid max-md:mt-10 max-md:max-w-full">
    <div className="flex gap-5 max-md:flex-col max-md:gap-0 h-full">
      <div className="flex flex-col w-[29%] max-md:ml-0 max-md:w-full pl-5 h-full">
        <div className="flex flex-col mt-6 text-base font-medium leading-6 text-center text-black max-md:mt-10">
          <img loading="lazy" src={imageSrc} alt={title} className="self-center aspect-square w-[75px]" />
          <div className="mt-6">{title}</div>
        </div>
      </div>
      <div className="flex flex-col ml-5 w-[71%] max-md:ml-0 max-md:w-full h-full">
        <div
          className="grow px-4 pt-5 pb-20 text-base leading-6 text-black border-l-2 border-gray-200 border-solid max-md:pr-5 max-md:mt-5 h-full"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      </div>
    </div>
  </div>
);

/**
 * Benefit cards data
 */
const benefitCardsData: BenefitCardProps[] = [
  {
    imageSrc: "icon07-a.png",
    title: "Für alle Geräte geeignet",
    description:
      "Da wir auf aktuellen Standards aufbauen, bleibt es für dich einfach und unkompliziert. Dein Kontakt wird vollständig angelegt - mit allen gewünschten Informationen.",
  },
  {
    imageSrc: "icon07-b.png",
    title: "Zeit & Kosten sparen",
    description:
      "Digitale Visitenkarten sind ein Schritt in Richtung nachhaltiger Geschäftspraktiken, da sie die Verschwendung von Papier reduzieren. Zudem entfällt die Notwendigkeit, bei Änderungen ständig neue Karten drucken zu lassen.",
  },
  {
    imageSrc: "icon07-c.png",
    title: "Alles in Echtzeit",
    description:
      "Ob Sie eine Beförderung erhalten haben oder Ihre Kontaktdaten aktualisieren möchten - mit unserer digitalen vCard können Sie Änderungen in Echtzeit vornehmen.",
  },
  {
    imageSrc: "icon07-d.png",
    title: "Keine App notwendig",
    description:
      "Für den Austausch von Kontaktdaten bedarf es keiner App, weder von Ihrer noch von der Seite Ihres Gesprächspartners.",
  },
  {
    imageSrc: "icon07-e.png",
    title: "Unbegrenzt verfügbar",
    description:
      "Die Nutzung Ihrer digitalen Visitenkarte ist unbegrenzt. Keine Beschränkungen, keine Limits.",
  },
  {
    imageSrc: "icon07-f.png",
    title: "Aha-Effekt",
    description:
      "Keine Kontaktdaten mehr tippen! Karte scannen, Smartphone berühren, Kontakte automatisch importieren. Schnell. Einfach. Effizient.",
  }
];

/**
 * Benefits07 component
 * @returns {JSX.Element} - Rendered component
 */
const Benefits07: React.FC = () => {
  return (
    <div className="flex justify-center items-center px-16 py-20 bg-white max-md:px-5" id="benefits07">
      <div className="flex flex-col items-center mt-14 max-w-full w-[1200px] max-md:mt-10">
        <div className="flex gap-2.5 text-3xl font-black leading-8 text-center text-amber-300 whitespace-nowrap">
          <div className="grow"></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="mt-2.5 text-3xl font-extralight leading-8 text-center text-black">
          Beeindrucken und Überzeugen
        </div>
        <h2 className="mt-3.5 text-2xl font-medium leading-9 text-center text-black uppercase max-md:max-w-full">
          Das sind die Vorteile einer NFC Visitenkarte
        </h2>
        <div className="self-stretch mt-20 max-md:mt-10 max-md:max-w-full">
          <div className="grid grid-cols-2 gap-5 max-md:grid-cols-1">
            {benefitCardsData.map((card, index) => (
              <div key={index} className="flex flex-col w-full max-md:ml-0 max-md:w-full">
                <BenefitCard {...card} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits07;
