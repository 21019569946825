import React, { useState } from 'react';
import PriceTable from './PriceTable';
import AdditionalOption from './AdditionalOption';

function CostOverview11() {
  const [selectedMaterial, setSelectedMaterial] = useState('PVC');

  const handleMaterialChange = (material: string) => {
    setSelectedMaterial(material);
  };

  const additionalOptions = [
    {
      option: 'Individuelles Corporate Design für vCard-Profil',
      price: '+ 99,00 € netto',
      onOptionClick: () => console.log('Option 1 clicked'),
    },
    {
      option: 'Business Account – Zentrale Verwaltung',
      price: 'ab mtl. 0,49 EUR € netto je Profil',
      onOptionClick: () => console.log('Option 2 clicked'),
    },
    {
      option: 'Zusatzkarten für NFC Visitenkarten',
      price: '+ 2,50 € netto (PVC), 3,00 € netto (Bambus) oder 5,00 € netto (Metall)',
      onOptionClick: () => console.log('Option 3 clicked'),
    },
  ];

  const priceData = {
    PVC: {
      standard: [
        { quantity: 10, pricePerCard: '4,90 €' },
        { quantity: 25, pricePerCard: '3,90 €' },
        { quantity: 50, pricePerCard: '2,90 €' },
        { quantity: 100, pricePerCard: '1,90 €' },
      ],
      personalized: [
        { quantity: 10, pricePerCard: '5,90 €' },
        { quantity: 25, pricePerCard: '4,90 €' },
        { quantity: 50, pricePerCard: '3,90 €' },
        { quantity: 100, pricePerCard: '2,90 €' },
      ],
    },
    HOLZ: {
      standard: [
        { quantity: 10, pricePerCard: '5,90 €' },
        { quantity: 25, pricePerCard: '4,90 €' },
        { quantity: 50, pricePerCard: '3,90 €' },
        { quantity: 100, pricePerCard: '2,90 €' },
      ],
      personalized: [
        { quantity: 10, pricePerCard: '6,90 €' },
        { quantity: 25, pricePerCard: '5,90 €' },
        { quantity: 50, pricePerCard: '4,90 €' },
        { quantity: 100, pricePerCard: '3,90 €' },
      ],
    },
    METALL: {
      standard: [
        { quantity: 5, pricePerCard: '29,90 €' },
        { quantity: 10, pricePerCard: '24,90 €' },
        { quantity: 25, pricePerCard: '19,90 €' },
        { quantity: 50, pricePerCard: '15,90 €' },
      ],
      personalized: [
        { quantity: 5, pricePerCard: '30,90 €' },
        { quantity: 10, pricePerCard: '25,90 €' },
        { quantity: 25, pricePerCard: '20,90 €' },
        { quantity: 50, pricePerCard: '16,90 €' },
      ],
    },
    PAPIER: {
      standard: [
        { quantity: 100, pricePerCard: '0,15 €' },
        { quantity: 300, pricePerCard: '0,13 €' },
        { quantity: 500, pricePerCard: '0,10 €' },
        { quantity: 1000, pricePerCard: '0,09 €' },
      ],
      personalized: [
        { quantity: 100, pricePerCard: '0,15 €' },
        { quantity: 300, pricePerCard: '0,13 €' },
        { quantity: 500, pricePerCard: '0,10 €' },
        { quantity: 1000, pricePerCard: '0,09 €' },
      ],
    },
  };

  const renderPriceTables = () => {
    const material = selectedMaterial as keyof typeof priceData;
    const data = priceData[material];

    return (
      <>
        <div className="w-full max-md:w-full">
          <PriceTable
            title={`${selectedMaterial} Standard`}
            subtitle="(ohne Individualisierung je Karte)"
            priceData={data.standard}
          />
        </div>
        <div className="w-full max-md:w-full">
          <PriceTable
            title={`${selectedMaterial} Personalisiert`}
            subtitle="(individuelle Daten je Karte, z. B. Name, E-Mail etc.)"
            priceData={data.personalized}
          />
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col justify-center pt-12 bg-stone-50" id="costoverview11">
      <header className="flex gap-0 self-center max-w-full w-[315px]">
        <div className="flex flex-col">
          <nav className="flex gap-5 justify-between self-center px-5 max-w-full text-center whitespace-nowrap">
            {['PVC', 'HOLZ', 'METALL', 'PAPIER'].map((material) => (
              <button
                key={material}
                className={`text-base leading-5 px-4 ${
                  selectedMaterial === material ? 'text-blue-300 border border-blue-300 rounded' : 'text-black'
                }`}
                onClick={() => handleMaterialChange(material)}
              >
                {material}
              </button>
            ))}
          </nav>
          <div className="flex gap-0 mt-9 w-full">
            {['PVC', 'HOLZ', 'METALL', 'PAPIER'].map((material) => (
              <div
                key={material}
                className={`flex-1 h-[3px] ${
                  selectedMaterial === material ? 'bg-blue-300' : 'bg-zinc-300'
                }`}
              />
            ))}
          </div>
        </div>
      </header>
      <main className="flex gap-0 w-full bg-white max-md:flex-wrap max-md:max-w-full">
        <section className="flex grow shrink-0 justify-center items-center px-16 py-16 basis-0 bg-stone-50 w-fit max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col max-w-full w-[1199px]">
            <div className="max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-wrap justify-center">{renderPriceTables()}</div>
            </div>
            <div className="mt-10 w-full">
              <div className="flex w-full text-lg text-white mt-5">
                <div className="flex-1 px-3 py-3.5 bg-blue-300 border border-blue-300 border-solid leading-[138%] max-md:pr-5">
                  Zusatzoption
                </div>
                <div className="flex-1 px-6 py-4 text-right bg-blue-300 border-t border-r border-b border-blue-300 border-solid leading-[142%] max-md:pl-5">
                  Preis (netto)
                </div>
              </div>
              {additionalOptions.map((option, index) => (
                <AdditionalOption
                  key={index}
                  option={option.option}
                  price={option.price}
                  onOptionClick={option.onOptionClick}
                />
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default CostOverview11;
