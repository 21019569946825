import React from "react";

const ImprintPage: React.FC = () => {
  const emailUser = "info";
  const emailDomain = "litfax-bv.de";
  const email = `${emailUser}@${emailDomain}`;

  return (
    <div className="flex justify-center items-center px-16 py-14 bg-stone-50 max-md:px-5">
      <div className="max-w-full w-[1200px]">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-full max-md:ml-0">
            <h1 className="text-2xl leading-8 text-black w-full mt-10">Impressum</h1>
            <p className="mt-4 text-base leading-6 text-gray-700 w-full">
              litfax® GmbH - Verlag für Banken<br />
              Sitz der Gesellschaft: Berlin<br />
              Darßer Bogen 3<br />
              13088 Berlin
            </p>
            <p className="mt-4 text-base leading-6 text-gray-700 w-full">
              Telefon: +49 (0) 30 / 688 1919-70<br />
              Telefax: +49 (0) 30 / 688 1919-99<br />
              E-Mail: <a href={`mailto:${email}`} className="text-blue-600">{email}</a>
            </p>
            <p className="mt-4 text-base leading-6 text-gray-700 w-full">
              <strong>Vertreten durch:</strong><br />
              Peter Junge
            </p>
            <p className="mt-4 text-base leading-6 text-gray-700 w-full">
              <strong>Registereintrag:</strong><br />
              Eingetragen im Handelsregister.<br />
              Registergericht: Berlin<br />
              Registernummer: HRB 96160B
            </p>
            <p className="mt-4 text-base leading-6 text-gray-700 w-full">
              <strong>Umsatzsteuer-ID:</strong><br />
              Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz: DE241211663
            </p>
            <p className="mt-4 text-base leading-6 text-gray-700 w-full">
              <strong>Kontakt für Kundenanfragen</strong><br />
              Service-Hotline: +49 (0) 30 / 688 1919-70<br />
              Mo bis Fr: 08:30 bis 16:00 Uhr<br />
              E-Mail: <a href={`mailto:${email}`} className="text-blue-600">{email}</a><br />
              Telefax: +49 (0) 30 / 688 1919-99
            </p>
            <p className="mt-4 text-base leading-6 text-gray-700 w-full">
              <strong>Geschäftsadresse / Post an</strong><br />
              litfax® GmbH – Verlag für Banken<br />
              Darßer Bogen 3<br />
              D-13088 Berlin
            </p>
            <p className="mt-4 text-base leading-6 text-gray-700 w-full">
              Die Rechte für die Bilder auf dieser Webseite obliegen ausschließlich der Litfax GmbH – Verlag für Banken. Die Verwendung der Bilder ohne schriftliche Genehmigung ist nicht gestattet und wird juristisch verfolgt.
            </p>
            <p className="mt-4 text-base leading-6 text-gray-700 w-full text-justify">
              Die von uns verwendeten Inhalte sowie externen Links wurden von uns sorgfältig zusammengestellt. Der Betreiber dieses Internetauftritts hat keinen Einfluss auf nachträgliche Änderungen der verlinkten Webseiten. Er übernimmt weder Verantwortung für die Inhalte dieser Seiten, noch macht er sich diese zu Eigen. Im Falle illegaler, inkorrekter oder unvollständiger Inhalte sowie bei Schäden, die durch die Verwendung oder Nichtverwendung dieser Informationen entstehen, haftet ausschließlich der Anbieter der Webseite, auf welche verlinkt wurde. Für illegale, fehlerhafte oder unvollständige Inhalte sowie für Schäden, die durch die Nutzung oder Nichtnutzung der Informationen entstehen, haftet allein der Anbieter der Webseite, auf die verlinkt wurde. Die Haftung desjenigen, der lediglich auf die Veröffentlichung durch einen Hyperlink hinweist, ist ausgeschlossen.
            </p>
            <p className="mt-4 text-base leading-6 text-gray-700 w-full text-justify">
              Seit dem 15. Februar 2016 stellt die EU-Kommission eine Plattform für außergerichtliche Streitschlichtung bereit. Verbrauchern gibt dies die Möglichkeit, Streitigkeiten im Zusammenhang mit Ihrer Online-Bestellung zunächst ohne die Einschaltung eines Gerichts zu klären. Die Streitbeilegungs-Plattform ist unter dem externen Link <a href="http://ec.europa.eu/consumers/odr/" className="text-blue-600">http://ec.europa.eu/consumers/odr/</a> erreichbar.
            </p>
            <p className="mt-4 text-base leading-6 text-gray-700 w-full text-justify">
              In diesem Zusammenhang sind wir verpflichtet, Sie auf unsere E-Mailadresse <a href={`mailto:${email}`} className="text-blue-600">{email}</a> hinzuweisen. Wir sind stets bemüht, Meinungsverschiedenheiten aus unserem Vertrag einvernehmlich beizulegen. Darüber hinaus haben wir uns jedoch entschieden, nicht an einem Schlichtungsverfahren teilzunehmen. Hierzu sind wir auch nicht verpflichtet.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImprintPage;
