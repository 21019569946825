import React from "react";

const AGBPage: React.FC = () => {
  return (
    <div className="flex justify-center items-center px-16 py-14 bg-stone-50 max-md:px-5">
      <div className="max-w-full w-[1200px]">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-full max-md:ml-0">
            <div className="col-right">
              <h1 className="text-2xl leading-8 text-black w-full">Allgemeine Geschäftsbedingungen</h1>
              <p className="mt-4 text-base leading-6 text-black w-full">
                <strong className="text-xl leading-7 text-black w-full mt-4">Allgemeine-Geschäftsbedingungen für my-litfax | printroom und andere Litfax Portale</strong>
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                <strong>Für Geschäfte, die zwischen Ihnen und uns über eines unserer Online-Druckportale zustande kommen, gilt folgendes Grundverständnis:</strong>
              </p>
              <ul className="mt-4 text-base leading-6 text-black w-full list-disc pl-5">
                <li>Wir haften für das, wofür wir verantwortlich sind. Und zwar im Umfang des Auftragswertes. Wir entscheiden, ob wir einen Auftrag annehmen oder nicht. Auch nach Versand einer automatisierten Auftragsbestätigung haben wir das Recht, die Ausführung abzulehnen. Wir haften nicht für Folgeschäden, entgangene Umsätze oder ähnliche Nachteile.</li>
                <li>Produktionsbedingt können Mehr- oder Minderlieferungen vorkommen. Mehr- oder Minderlieferungen bis zu 10 % der bestellten Auflage können wegen Geringfügigkeit der Abweichung nicht beanstandet werden. Dies gilt auch, wenn die Serviceoption Lieferungsaufteilung gewählt ist. Berechnet wird die gelieferte Menge. Hierzu zählt auch Makulatur, die zur evtl. Verwendung mitgeschickt wurde.</li>
                <li>Sie als unser Kunde müssen über die erforderlichen Nutzungsrechte für die Daten verfügen, die Sie uns zum Druck übersenden. Wir können dies nicht prüfen und daher auch nicht haftbar gemacht werden.</li>
                <li>Wir behalten uns Irrtümer und Fehler in der Preiskalkulation vor. In diesem Fall findet eine Nachkalkulation statt, nach der Sie von Ihrer Bestellung vor Produktionsbeginn zurücktreten können.</li>
                <li>Wir informieren auf my-litfax.de über die von uns angebotenen Produkte und Dienstleistungen. Wenn Sie als unser Kunde diese buchen, dann haben Sie sich über die von uns erläuterte Produktbeschaffenheit, Versand, Termine etc. informiert und verpflichten sich auch zur Zahlung der von Ihnen bestellten Leistung.</li>
                <li>Der Versand erfolgt grundsätzlich auf Rechnung des Kunden. Ist der Kunde Unternehmer, so geht, sobald die Ware an einen von uns beauftragten Paketservice, Spedition oder Post Spediteur, übergeben wurde, spätestens bei Verlassen unserer Unternehmensräume, die Gefahr auf den Kunden über.</li>
                <li>Gleiches gilt auch bei Franko- und Frei-Haus-Lieferungen. Sollte eine Sendung beschädigt beim Kunden ankommen - und dieser ist kein Verbraucher - so muss die Sendung unverzüglich beim Transportunternehmen angezeigt werden. Sollten berechtigte Gründe zur Beanstandung geben, muss die Ware sofort nach Lieferung reklamiert werden.</li>
                <li>Im Streitfall ist der Gerichtsstand Berlin</li>
              </ul>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>Online-Streitschlichtung</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Wir beteiligen uns nicht an einem System zur Alternativen Streitschlichtung im Sinne der EU-VO Nr. 524/2013. Gerne können Sie sich aber an unseren Kundenservice wenden. Sie erreichen uns zum Beispiel in allen Angelegenheiten unter info@my-litfax.de. Den gleichwohl vorgeschriebenen Link zur Plattform der EU zur Online-Streitschlichtung (Verfügbarkeit liegt in der Verantwortung der EU) erreichen Sie <a href="https://ec.europa.eu/odr" target="_blank">hier</a>.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 1 Geltungsbereich, Kundeninformationen</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Die hier aufgeführten Allgemeinen Geschäftsbedingungen (AGB) gelten für alle über die Internetseite www.my-litfax.de geschlossenen Rechtsgeschäfte mit der Litfax GmbH – Verlag für Banken (nachfolgend litfax®). Abweichende Regelungen bedürfen der vorherigen schriftlichen Bestätigung der litfax®, nicht ausreichend ist die Bezugnahme der litfax® auf ein Schreiben des Auftraggebers, welches AGB des Auftraggebers enthält. Spätestens mit der Entgegennahme der Ware gelten die folgenden Geschäftsbedingungen als angenommen. Entgegenstehende oder von diesen Geschäftsbedingungen abweichende Bedingungen werden von litfax® nicht anerkannt. Die Vertragssprache ist Deutsch.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 2 Vertragsschluss</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">(1) Die Angebote im OnlineShop stellen eine unverbindliche Aufforderung an den Auftraggeber dar, Waren zu kaufen.</p>
              <p className="mt-4 text-base leading-6 text-black w-full">(2) Nach Eingabe seiner Daten und mit dem Anklicken des Bestellbuttons gibt der Auftraggeber ein verbindliches Angebot auf Abschluss eines Kaufvertrags ab. Der Auftraggeber kann eine verbindliche Bestellung aber auch telefonisch oder per Telefax abgeben.</p>
              <p className="mt-4 text-base leading-6 text-black w-full">(3) Mit der unverzüglich per E-Mail bzw. Telefax versandten Zugangsbestätigung wird gleichzeitig auch die Annahme des Angebots des Auftraggebers erklärt und der Kaufvertrag damit abgeschlossen. Bei einer telefonischen Bestellung kommt der Kaufvertrag zustande, wenn litfax® das Angebot sofort annimmt. Wird das Angebot nicht sofort angenommen, dann ist der Auftraggeber auch nicht mehr daran gebunden.</p>
              <p className="mt-4 text-base leading-6 text-black w-full">(4) Angebote, die litfax® dem Auftraggeber auf seine Anfrage schriftlich oder mündlich unterbreitet, haben eine Gültigkeit von vier Wochen. Danach muss der Auftraggeber ein neues Angebot einholen. Abweichende Regelungen bedürfen der Schriftform.</p>
              <p className="mt-4 text-base leading-6 text-black w-full">(5) Der Auftraggeber kann ferner einen verbindlichen Auftrag erteilen, indem er seine druckreifen Daten per E-Mail an litfax® schickt.</p>
              <p className="mt-4 text-base leading-6 text-black w-full">(6) litfax® behält sich das Recht vor, Druckaufträge abzulehnen, deren Inhalte gegen geltendes Recht verstoßen oder moralisch verwerflich z.B. pornografisch, menschenverachtend, rassistisch, faschistisch oder gewaltverherrlichend sind oder einen solchen Hintergrund haben.</p>
              <p className="mt-4 text-base leading-6 text-black w-full">(7) Der Zwischenverkauf ist vorbehalten</p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 3 Widerrufsrechte und Ausschluss des Widerrufs bei Verbraucherverträgen</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">(1) Widerrufsrecht bei Lieferung von Waren, die nicht kundenspezifisch hergestellt wurden und in einer Sendung ausgeliefert werden</p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>Widerrufsbelehrung</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Frist beginnt ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">Um Ihr Widerrufsrecht auszuüben, müssen Sie uns</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Litfax GmbH – Verlag für Banken</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Darßer Bogen 3, 13088 Berlin</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Telefon: 030 / 688 1919 – 70, Telefax: 030 / 688 1919 – 99</p>
              <p className="mt-4 text-base leading-6 text-black w-full">E-Mail: info@my-litfax.de</p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>Widerrufsformular</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden es zurück.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">An</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Litfax GmbH – Verlag für Banken</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Darßer Bogen 3</p>
              <p className="mt-4 text-base leading-6 text-black w-full">13088 Berlin</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Deutschland</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Fax: +49 (0)30 688 1919 70</p>
              <p className="mt-4 text-base leading-6 text-black w-full">E-Mail: info@litfax-bv.de</p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">_______________________________________________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">_______________________________________________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Bestellt am (*) ____________ / erhalten am (*) __________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">________________________________________________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Name des/der Verbraucher(s)</p>
              <p className="mt-4 text-base leading-6 text-black w-full">________________________________________________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Anschrift des/der Verbraucher(s)</p>
              <p className="mt-4 text-base leading-6 text-black w-full">________________________________________________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>
              <p className="mt-4 text-base leading-6 text-black w-full">_________________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Datum</p>
              <p className="mt-4 text-base leading-6 text-black w-full">(*) Unzutreffendes streichen</p>
              <p className="mt-4 text-base leading-6 text-black w-full">&nbsp;</p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>Folgen des Widerrufs</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde etwas anderes ausdrücklich vereinbart. In keinem Fall werden Ihnen wegen dieser Rückzahlung Bankentgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an Litfax GmbH – Verlag für Banken, Darßer Bogen 3, 13088 Berlin, zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren. Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>Besonderer Hinweis</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Ihr Widerrufsrecht erlischt vorzeitig, wenn wir auf Ihren ausdrücklichen Wunsch vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages beginnen.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Bitte vermeiden Sie Beschädigungen und Verunreinigungen der Ware. Senden Sie die Ware bitte in Originalverpackung mit sämtlichem Zubehör und mit allen Verpackungsbestandteilen an uns zurück. Verwenden Sie ggf. eine schützende Umverpackung. Wenn Sie die Originalverpackung nicht mehr besitzen, sorgen Sie bitte mit einer geeigneten Verpackung für einen ausreichenden Schutz vor Transportschäden.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">Senden Sie die Ware bitte nicht unfrei an uns zurück.</p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>Ende der Widerrufsbelehrung</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">(2) Widerrufsrecht bei Lieferung von mehreren Waren, die nicht kundenspezifisch hergestellt wurden und in mehreren Sendungen ausgeliefert werden</p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>Widerrufsbelehrung</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">Um Ihr Widerrufsrecht auszuüben, müssen Sie uns</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Litfax GmbH – Verlag für Banken</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Darßer Bogen 3, 13088 Berlin</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Telefon: 030 / 688 1919 – 70, Telefax: 030 / 688 1919 – 99</p>
              <p className="mt-4 text-base leading-6 text-black w-full">E-Mail: info@my-litfax.de</p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>Widerrufsformular</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden es zurück.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">An</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Litfax GmbH – Verlag für Banken</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Darßer Bogen 3</p>
              <p className="mt-4 text-base leading-6 text-black w-full">13088 Berlin</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Deutschland</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Fax: +49 (0)30 688 1919 70</p>
              <p className="mt-4 text-base leading-6 text-black w-full">E-Mail: info@litfax-bv.de</p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden Dienstleistung (*)
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">_______________________________________________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">_______________________________________________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Bestellt am (*) ____________ / erhalten am (*) __________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">________________________________________________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Name des/der Verbraucher(s)</p>
              <p className="mt-4 text-base leading-6 text-black w-full">________________________________________________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Anschrift des/der Verbraucher(s)</p>
              <p className="mt-4 text-base leading-6 text-black w-full">________________________________________________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>
              <p className="mt-4 text-base leading-6 text-black w-full">_________________________</p>
              <p className="mt-4 text-base leading-6 text-black w-full">Datum</p>
              <p className="mt-4 text-base leading-6 text-black w-full">(*) Unzutreffendes streichen</p>
              <p className="mt-4 text-base leading-6 text-black w-full">&nbsp;</p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>Folgen des Widerrufs</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, dass Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde etwas anderes ausdrücklich vereinbart. In keinem Fall werden Ihnen wegen dieser Rückzahlung Bankentgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an Litfax GmbH – Verlag für Banken, Darßer Bogen 3, 13088 Berlin, zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren. Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zurückzuführen ist.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>Besonderer Hinweis</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Ihr Widerrufsrecht erlischt vorzeitig, wenn wir auf Ihren ausdrücklichen Wunsch vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages beginnen.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Bitte vermeiden Sie Beschädigungen und Verunreinigungen der Ware. Senden Sie die Ware bitte in Originalverpackung mit sämtlichem Zubehör und mit allen Verpackungsbestandteilen an uns zurück. Verwenden Sie ggf. eine schützende Umverpackung. Wenn Sie die Originalverpackung nicht mehr besitzen, sorgen Sie bitte mit einer geeigneten Verpackung für einen ausreichenden Schutz vor Transportschäden.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">Senden Sie die Ware bitte nicht unfrei an uns zurück.</p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>Ende der Widerrufsbelehrung</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">(3) Ausschluss des Widerrufsrechts bei Lieferung von Waren, die nach Kundenspezifikation hergestellt wurden</p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Das Widerrufsrecht besteht nicht bei Fernabsatzverträgen - über die Lieferung von Ware, die nicht vorgefertigt ist und für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten ist. Soweit die Herstellung der Druckerzeugnisse über die Internetseite von litfax® nach Kundenspezifikation erfolgt, besteht daher kein gesetzliches Widerrufsrecht. Ein vertragliches Widerrufsrecht wird nicht eingeräumt.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 4 Speicherung der Bestelldaten</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (1) Die Bestellung mit Einzelheiten zum geschlossenen Vertrag (z.B. Art des Produkts, Preis etc.) wird von litfax® gespeichert. Der Auftraggeber kann diese auch nach Vertragsschluss jederzeit über die Webseite von litfax® aufrufen.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (2) Als registrierter Kunde kann der Auftraggeber auf seine vorigen Bestellungen über den Login-Bereich (Kundenkonto) zugreifen.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 5 Korrekturmöglichkeiten</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Der Auftraggeber kann seine Eingaben vor Abgabe der Bestellung jederzeit mit der Löschtaste berichtigen. litfax® informiert den Auftraggeber auf dem Weg durch den Bestellprozess über weitere Korrekturmöglichkeiten. Der Auftraggeber kann den Bestellprozess auch jederzeit durch Schließen des Browser-Fensters komplett beenden. Die auf den Server von litfax® hochgeladenen Daten können nur dann noch verändert oder ausgetauscht werden, wenn der Druckauftrag noch nicht in die Weiterverarbeitung geleitet wurde.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">Ergänzungen zu Aufträgen können nach Bestellabschluss nicht vorgenommen werden.</p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 6 Rechte und Pflichten des Auftraggebers</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (1) Die Drucksachen und elektronischen Veröffentlichungen werden aufgrund von inhaltlichen Vorgaben des Auftraggebers erstellt. litfax® hat auf deren Inhalt keinen Einfluss. Der Auftraggeber versichert, dass er geeignete Rechte zur Nutzung, Weitergabe und Veröffentlichung aller übertragenen Daten (inklusive Text und Bildmaterial) besitzt. Der Auftraggeber stellt litfax® von den Ansprüchen Dritter frei, die daraus resultieren, dass Schutz- oder Urheberrechte Dritter durch die Produktion oder Verwendung der in Auftrag gegebenen Drucksachen verletzt werden oder dass die Drucksachen wettbewerbswidrige Inhalte enthalten oder gegen die guten Sitten verstoßen.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (2) Der Auftraggeber hat vertragsgemäß die gelieferten Daten zu prüfen. Der Dateneingang gilt bis 12.00 Uhr eines Werktages. Treffen Daten nach 12.00 Uhr ein, wird der Dateneingang dem nächsten Werktag zugerechnet. Die Druckfreigabe wird durch den Auftraggeber i.d.R. durch eine unterzeichnete Vorlage, E-Mail, Fax oder telefonisch erteilt. Für Druckdaten, welche in einem der Onlineangebote von litfax® gestaltet und erstellt wurden, erteilt der Auftraggeber bereits vor Abschluss der Bestellung seine rechtsverbindliche Produktionsfreigabe. Die Gefahr etwaiger Fehler geht mit der Druckfreigabe auf den Auftraggeber über, soweit es sich nicht um Fehler handelt, die erst während des Fertigungsvorgangs bzw. der Produktionszeit entstanden sind. Das Gleiche gilt für alle sonstigen Freigabeerklärungen des Auftraggebers zur weiteren Herstellung.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 7 Eigentumsvorbehalt</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (1) Der Kaufgegenstand bleibt bis zur vollständigen Bezahlung Eigentum von litfax®. Der Auftraggeber ist berechtigt, den gelieferten Gegenstand im ordnungsgemäßen Geschäftsgang weiterzuveräußern. Er tritt bereits jetzt alle Forderungen in Höhe des Rechnungswertes der Forderungen von litfax® bzw. entsprechend dem Wert der gelieferten Vorbehaltsware ab, die ihm durch die Weiterveräußerung gegen einen Dritten erwachsen. litfax® nimmt die Abtretung hiermit an. Nach der Abtretung ist der Auftraggeber zur Einziehung der Forderung ermächtigt. litfax® behält sich vor, die Forderung selbst einzuziehen, sobald der Auftraggeber seinen Zahlungsverpflichtungen nicht nachkommt und in Zahlungsverzug gerät.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (2) Die von litfax® zur Herstellung des Vertragserzeugnisses eingesetzten Betriebsgegenstände, insbesondere Daten, Druckplatten, Stanz- und Prägewerkzeuge bleiben, auch wenn sie gesondert berechnet werden, Eigentum von litfax® und werden nicht ausgeliefert.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 8 Anzeige von Mängeln</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Der Auftraggeber hat einen Mangel unverzüglich nach Ablieferung der Ware durch litfax® anzuzeigen. Kommt der Auftraggeber der Anzeigepflicht nicht nach, so gilt die Ware als genehmigt, es sei denn, dass es sich um einen Mangel handelt, der bei der Untersuchung nicht erkennbar war. Beanstandungen sind dann nicht berechtigt, wenn die Ursache einer Beanstandung nachweislich auf die Nichtbeachtung der Anleitungen zur Erstellung von Druckvorlagen zurückzuführen ist. Die Anleitungen (Datenblätter) werden jedem Kunden auf Wunsch vor Auftragserteilung, jedoch grundsätzlich ständig in den jeweiligen Onlineangeboten zugänglich gemacht. Eine unberechtigte Beanstandung liegt insbesondere bei fehlerhaften oder für die Belichtung unzureichenden Druckdaten (z.B. Daten mit RGB-Farbraum, geringer Bildauflösung, falsche Dokumentformate oder Formatangaben) vor. Word-Dateien, Powerpoint-Dateien o. ä. für die Belichtung ungeeignete Dateiformate müssen eigenständig in PDF-Dateien umgewandelt werden. Bei einer nachträglichen Datenumwandlung in PDF-Dateien können Farbunterschiede auftreten, wofür nach Druckfreigabe keine Haftung übernommen wird.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 9 Gewährleistungsansprüche, Verjährung</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Weist ein Teil der gelieferten Ware Mängel auf, so berechtigt dies nicht zur Beanstandung der gesamten Lieferung, es sei denn, dass die Teillieferung für den Auftraggeber nachweislich ohne Interesse ist. Geringfügige Farbabweichungen vom Original können bei farbigen Reproduktionen in allen Druckverfahren nicht beanstandet werden. Dies gilt auch für den Vergleich zwischen Andruck und Auflagendruck. litfax® haftet für Abweichungen in der Beschaffenheit des verwendeten Materials nur bis zur Höhe der eigenen Ansprüche gegen den jeweiligen Zulieferer. In diesem Fall ist litfax® von seiner Haftung befreit, wenn er seine Ansprüche gegen die Zulieferer an den Auftraggeber abtritt. Die Gewährleistungsansprüche wegen Mängel der Kaufsache verjähren in einem Jahr ab Gefahrübergang. Von dieser Regelung ausgenommen sind Schadensersatzansprüche, Ansprüche wegen Mängel, die litfax® arglistig verschweigt, und Ansprüche aus einer Garantie, die litfax® für die Beschaffenheit der Sache übernommen hat. Ebenfalls ausgenommen ist der Rückgriffsanspruch des Auftraggebers nach § 478 BGB. Für diese ausgenommenen Ansprüche gelten die gesetzlichen Verjährungsfristen.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 10 Haftungsbeschränkung</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (1) litfax® schließt die Haftung für leicht fahrlässige Pflichtverletzungen aus, sofern diese keine vertragswesentlichen Pflichten, Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, Garantien oder Ansprüche nach dem Produkthaftungsgesetz betreffen. Gleiches gilt für Pflichtverletzungen der Erfüllungsgehilfen und der gesetzlichen Vertreter von litfax®. Zu den vertragswesentlichen Pflichten gehört insbesondere die Pflicht, dem Auftraggeber die Sache zu übergeben und ihm das Eigentum daran zu verschaffen. Weiterhin hat litfax® dem Auftraggeber die Sache frei von Sach- und Rechtsmängeln zu verschaffen.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (2) Hat der Auftrag Veredelungsarbeiten oder Weiterverarbeitung von Druckerzeugnissen zum Gegenstand, so haftet litfax® nicht für die dadurch verursachte Beeinträchtigung des zu veredelnden oder weiterzuverarbeitenden Erzeugnisses, sofern nicht der Schaden vorsätzlich oder grob fahrlässig verursacht wurde. Für Druckdaten, welche in einem der Onlineangebote von litfax® gestaltet und erstellt wurden, erteilt der Auftraggeber bereits vor Abschluss der Bestellung seine rechtsverbindliche Produktionsfreigabe. Die Gefahr etwaiger Fehler geht mit der Druckfreigabe auf den Auftraggeber über, soweit es sich nicht um Fehler handelt, die erst während des Fertigungsvorgangs bzw. der Produktionszeit entstanden sind. Das Gleiche gilt für alle sonstigen Freigabeerklärungen des Auftraggebers zur weiteren Herstellung.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 11 Preise, Mengendifferenzen</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (1) Die Fertigungsdauer ist produktbezogen und online einsehbar. Es sind Zirkanagaben und können temporär variiren. Die im Angebot von litfax® genannten Preise sind gültig, sofern die bei der Angebotsabgabe zugrunde gelegten Auftragsdaten unverändert bleiben. Alle von uns genannten Preise sind Endpreise in Euro und enthalten die gesetzliche Umsatzsteuer, wenn nicht ausdrücklich Nettopreise angegeben und die Umsatzsteuer getrennt ausgewiesen wird.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (2) Aus produktions- und fertigungstechnischen Gründen behält sich litfax® das Recht auf eine branchenübliche Über- bzw. Unterlieferung in Höhe von max. 10% vor. Diese wird von litfax® entsprechend in Rechnung gestellt.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (3) Für IT-Projekte (Consulting, Service oder Programmierung) gelten separate Preise und werden gesondert, je Projekt und Kunde, abgeschlossen.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 12 Zahlung und Zahlungsverzug</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Die Lieferung erfolgt grundsätzlich per Vorauszahlung. Gegenüber Ansprüchen von litfax® kann der Auftraggeber nur mit unbestrittenen oder rechtskräftig festgestellten Forderungen aufrechnen. litfax® stellt dem Auftraggeber die Kosten in Rechnung, die dadurch entstehen, dass der Auftraggeber die Lieferung nicht annimmt. Ist eine Bereitstellung außergewöhnlich großer Papier- und Kartonmengen, besonderer Materialien oder Vorleistungen nötig, so kann hierfür Vorauszahlung verlangt werden. Ist die Erfüllung des Zahlungsanspruches wegen einer nach Vertragsabschluss eingetretenen oder bekannt gewordenen Verschlechterung der Vermögensverhältnisse des Auftraggebers gefährdet, so kann litfax® Vorauszahlung und sofortige Zahlung aller offenen, auch der noch nicht fälligen Rechnungen verlangen, noch nicht ausgelieferte Waren zurückbehalten sowie die Weiterarbeit an noch laufenden Aufträgen einstellen. Diese Rechte stehen litfax® auch zu, wenn der Auftraggeber trotz einer verzugsbegründenden Mahnung keine Zahlung leistet. Rechnungen müssen innerhalb von 14 Tagen beglichen werden. litfax® behält sich das Recht vor, vier Wochen nach nicht bezahlter Rechnung einen Mahnbescheid zu beantragen. Bereits erfolgte Lieferungen können bei nicht geleisteter Zahlung jederzeit zurückgefordert werden.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 13 Versand und Lieferung</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (1) Die Lieferung erfolgt durch die Deutsche Post, Paketdienst oder eine Spedition. Die Ware ist nach den jeweiligen Speditionsbedingungen des Transportunternehmens versichert. Zusätzliche Versicherungen werden nur auf ausdrücklichen, schriftlich übermittelten Wunsch des Auftraggebers vorgenommen und gehen zu dessen Lasten.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (2) Die Produktionszeit bzw. der Fertigungsvorgang beginnt am Tag nach der Druckfreigabe bzw. dem Zahlungseingang. Liefertermine werden im Online-Angebot bei den einzelnen Produkten vorgeschlagen und sind auf Basis der Vertragsbedingungen nicht zwingend verbindlich. Für Produkte, die nach einem schriftlichen Angebot geordert werden, gilt der schriftlich bestätigte Liefertermin. Andere Liefertermine müssen mit dem Kundenberater abgesprochen und schriftlich bestätigt werden. Werden Lieferungen vom Kunden nicht angenommen oder können mehrfach vom Transportunternehmen insbesondere wegen falscher Adressangaben nicht zugestellt werden, wird die Ware automatisch an uns retourniert. Eine wiederholte Anlieferung wird dann zusätzlich berechnet.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 14 Verwahrung, Versicherung</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Vorlagen, Rohstoffe, Datenträger, Druckträger und andere der Wiederverwendung dienende Gegenstände sowie Halb- und Fertigerzeugnisse werden nur nach vorheriger Vereinbarung über den Auslieferungstermin hinaus verwahrt. Diese Verwahrung bedarf der besonderen Vergütung. Für eine Versicherung der genannten Gegenstände oder Daten hat litfax® bei Bedarf selbst zu sorgen.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 15 Rückgaberecht</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (1) Der Kunde hat das Recht, die Ware innerhalb von 30 Tagen nach Erhalt mit Angabe von Gründen zurückzugeben.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (2) Der Kunde muss mit litfax® telefonisch oder schriftlich Kontakt aufnehmen, um den Wunsch der Rückgabe anzuzeigen. Die unbenutzte Ware ist möglichst in der Originalverpackung zur Abholung bereitzustellen.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (3) Die Kosten für die Rücksendung trägt der Kunde. Die Rücksendung muss immer auf versichertem, nachverfolgbarem Weg erfolgen. Im Falle einer fehlerhaften oder beschädigten Sendung trägt die litfax® die Rücksendekosten. Die Litfax GmbH behält sich vor, Kosten für die Bearbeitung der Rückgabe zur verlangen. Dies erfolgt in Verrechnung mit der Warengutschrift.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (4) Das Rückgaberecht ist ausgeschlossen bei Computern, bei Software mit gebrochenem Siegel, bei allen Büromöbeln, die in unterschiedlichen Farbvarianten erhältlich sind und in der Regel nach Bestelleingang gefertigt werden, bei solchen Waren, die nicht in unseren jeweils aktuellen Werbemitteln vorgestellt sind und/oder speziell auf Kundenwunsch angefertigt worden sind, sowie jegliche Sonderanfertigungen und bei Verbrauchsmaterialien, die sich nicht mehr in ungeöffneter Originalverpackung befinden.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">Individuell gefertigte personalisierte Produkte besitzen kein Rückgaberecht.</p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 16 Datenschutz & Werbesperre</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (1) Es wird darauf hingewiesen, dass die Kundendaten getrennt als Bestands- und als Abrechnungsdaten im Rahmen der gültigen datenschutzrechtlichen Regelungen gespeichert werden.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (2) Der Kunde hat jederzeit die Möglichkeit, durch Anruf, Schreiben, Telefax oder E-Mail-Werbesendungen zu widersprechen. Im Falle des Widerspruchs werden die Kundendaten für die jeweiligen oder alle Werbemittel gesperrt.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 17 Schlussbestimmungen</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (1) Es gilt das Recht der Bundesrepublik Deutschland, es sei denn, die Geltung deutschen Rechts ist aufgrund zwingender Normen ausgeschlossen.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (2) Die Bestimmungen des UN-Kaufrechts finden keine Anwendung.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (3) Für Verträge mit Kaufleuten, juristischen Personen des öffentlichen Rechts oder öffentlich-rechtliche Sondervermögen ist ausschließlicher Erfüllungsort und Gerichtsstand der Geschäftssitz des Auftragnehmers (D-13088 Berlin).
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (4) Hat der private Endverbraucher keinen Wohnsitz innerhalb der Europäischen Union, so ist unser Geschäftssitz Gerichtsstand.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (5) Im Verkehr mit Endverbrauchern innerhalb der Europäischen Union kann auch das Recht am Wohnsitz des Endverbrauchers anwendbar sein, sofern es sich zwingend um verbraucherrechtliche Bestimmungen handelt.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full"><strong>§ 18 Salvatorische Klausel</strong></p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Sollte eine oder mehrere der genannten Regelungen gegen zwingende Gesetzesvorschriften verstoßen oder in sonstiger Weise unwirksam/undurchführbar sein bzw. werden, so tangiert dies nicht die Wirksamkeit der übrigen Bestimmungen. Die unwirksame oder undurchführbare Bestimmung gilt als ersetzt, durch eine die in gesetzlich zulässiger Weise wirtschaftlich der Regelung am nächsten kommt.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">&nbsp;</p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                <strong className="text-xl leading-7 text-black w-full mt-4">Besondere Bedingungen für NFC-Produkte</strong>
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                Die speziellen Bedingungen haben Vorrang für die genannten Produkte und Dienstleistungen und ergänzen die allgemeinen Bedingungen. Die allgemeinen Bedingungen gelten, wenn in den speziellen Bedingungen keine Regelungen festgelegt wurden. Wenn in den speziellen Bedingungen nur teilweise Regelungen getroffen wurden, gelten die allgemeinen Bedingungen zusätzlich in diesem Fall.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                <strong className="text-xl leading-7 text-black w-full mt-4">§ 1 Digitale NFC-Visitenkarten</strong>
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">
                (1) Mit dem Kauf unserer „Digitale NFC-Visitenkarte“ erhält der Kunde eine physische Visitenkarte mit individuellem Layout sowie ein digitales Profil, welches auf Grundlage der übermittelten Daten eingerichtet wird. Das digitale Profil ist online abrufbar und mit der physischen Visitenkarte verknüpft.
              </p>
              <p className="mt-4 text-base leading-6 text-black w-full">(2) Durch die Nutzung unserer digitalen Produkte bestätigt der Kunde, dass er zur Verwendung und Übertragung sämtlicher auf dem Produkt gespeicherter digitaler Daten (z.B. Personendaten, Firmendaten) berechtigt ist. Der Kunde übernimmt die volle Haftung für jegliche Ansprüche Dritter, die aufgrund unberechtigter Nutzung der digitalen Daten erhoben werden. Der Kunde übernimmt die Verantwortung, uns von allen Ansprüchen Dritter freizustellen und jeglichen Schaden zu ersetzen, der uns durch eine Verletzung von Rechten Dritter entsteht. Dies umfasst auch die Kosten für rechtliche Schritte (Anwälte und Gerichte).</p>
              <p className="mt-4 text-base leading-6 text-black w-full">(3) Der Kunde bestätigt hiermit, dass er über sämtliche Vervielfältigungs-, Nutzungs- und Reproduktionsrechte an den eingereichten Inhalten verfügt.</p>
              <p className="mt-4 text-base leading-6 text-black w-full">(4) Falls LITFAX den Service zur Bereitstellung des Online-Profils einstellt, hast du das Recht, deinen Vertrag mit einer Kündigungsfrist von 4 Wochen zu beenden. Eine schriftliche Kündigung, beispielsweise per E-Mail, ist dafür ausreichend. Bitte beachte, dass in diesem Fall keine Rückerstattungsansprüche bestehen.</p>
              <p className="mt-4 text-base leading-6 text-black w-full">(5) Der my-litfax | Digi-vCard Account ist ein Dauerschuldverhältnis. Wenn der Kunde den BUSINESS Account hinzugebucht hat, beträgt die Vertragslaufzeit 12 Monate. Der Vertrag verlängert sich automatisch, wenn der Kunde nicht spätestens 3 Monate vor Ablauf kündigt. Eine wirksame Kündigung kann schriftlich erfolgen, beispielsweise per E-Mail. Im Falle einer Kündigung seitens LITFAX gemäß Absatz 5 wird dem Kunden der anteilige Betrag für den BUSINESS Account erstattet, basierend auf der Anzahl der noch verbleibenden Monate der Vertragslaufzeit.</p>
              <p className="mt-4 text-base leading-6 text-black w-full">(6) Wenn der Kunde gegen die Bestimmungen des Absatzes 2 bis 4 oder gesetzliche Vorschriften verstößt, behalten wir uns das Recht vor, das Online-Profil des Kunden jederzeit zu sperren und das Vertragsverhältnis außerordentlich zu kündigen.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AGBPage;
