import React from "react";

const PrivacyPage: React.FC = () => {
  return (
    <div className="flex justify-center items-center px-16 py-14 bg-stone-50 max-md:px-5">
      <div className="max-w-full w-[1200px]">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-full max-md:ml-0">
            <h2 className="text-2xl leading-8 text-black w-full">Datenschutz</h2>
            <p className="mt-4 text-base leading-6 text-black w-full"></p>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>Datenschutzinformationen und IT-Sicherheit</span>
            </p>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>Verantwortlicher:</span>
            </p>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Der Verantwortliche im Sinne der EU Datenschutz-Grundverordnung (DSGVO), des Bundesdatenschutzgesetzes (BDSG) und etwaiger sonstiger nationalen Datenschutzgesetze oder datenschutzrechtlicher Bestimmungen ist die
              </span><br />
              <br />
              Litfax GmbH – Verlag für Banken<br />
              Darßer Bogen 3<br />
              13088 Berlin<br />
              Deutschland<br />
              <br />
              Tel.: +49 (0)30 6881919 70<br />
              E-Mail: <a href="mailto:info@litfax-bv.de" className="text-blue-600">info@litfax-bv.de</a><br />
              Website: <a href="https://litfax-bv.de/" className="text-blue-600">https://litfax-bv.de/</a>
            </p>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>Personenbezogene Daten</span>
            </p>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Personenbezogene Daten sind Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person (Betroffener). Dazu gehören der Name, die E-Mail-Adresse oder die Telefonnummer sowie Daten über Vorlieben, Hobbies, getätigte Internet-Einkäufe oder Webseiten-Besuche, immer vorausgesetzt, dass diese Information mit einer Person verbunden ist oder in Verbindung gebracht werden kann.
              </span><br /><br />
              <span>Allgemeines zur Datenverarbeitung</span><br /><br />
              <span>Umfang der Verarbeitung personenbezogener Daten</span><br />
              <span>
                Wir erheben und verwenden personenbezogene Daten (Verarbeitung) unserer Nutzer grundsätzlich nur soweit dies zur Erbringung unserer Leistungen und etwaiger damit verbundener Nebenleistungen erforderlich ist. Die Erhebung und Verwendung personenbezogener Daten erfolgt regelmäßig nur nach Einwilligung der betroffenen Person. Ausnahmen gelten für solche Fälle, in denen die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet oder geboten ist. Die vorliegende Datenschutzerklärung soll Ihnen Transparenz über die erhobenen und verarbeiteten personenbezogenen Daten verschaffen. Hierbei ist für jeden Anwendungsfall dasselbe Schema zugrunde gelegt:
              </span>
            </p>
            <ul className="list-disc pl-5 mt-4 text-base leading-6 text-black w-full">
              <li>Beschreibung, Umfang der Datenverarbeitung</li>
              <li>Rechtsgrundlage</li>
              <li>Zweck</li>
              <li>Dauer der Speicherung</li>
              <li>Widerruf, Widerspruch und Ende der Verarbeitung</li>
            </ul>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>Rechtsgrundlage für die Verarbeitung personenbezogener Daten</span>
            </p>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient diese in Verbindung mit Art. 6 Abs. 1 Satz 1 Buchst. a und Art. 7 DSGVO als Rechtsgrundlage für die Verarbeitung personenbezogener Daten. Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 Satz 1 Buchst. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind. Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 Satz 1 Buchst. c DSGVO als Rechtsgrundlage. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 Satz 1 Buchst. d DSGVO als Rechtsgrundlage. Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 Satz 1 Buchst. f DSGVO als Rechtsgrundlage für die Verarbeitung.
              </span>
            </p>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>Datenlöschung, Einschränkung der Verarbeitung und Speicherdauer</span>
            </p>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Die personenbezogenen Daten der betroffenen Person werden gelöscht, wenn und sobald die Verarbeitung für den beabsichtigten Verarbeitungszweck nicht mehr notwendig ist. Eine Speicherung kann darüber hinaus allerdings dann erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht. Die Verarbeitung der Daten wird eingeschränkt, wenn
              </span>
            </p>
            <ol className="list-decimal pl-5 mt-4 text-base leading-6 text-black w-full">
              <li>die Richtigkeit der personenbezogenen Daten von der betroffenen Person bestritten wird, und zwar für eine Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,</li>
              <li>die Verarbeitung unrechtmäßig ist und die betroffene Person die Löschung der personenbezogenen Daten ablehnt und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt;</li>
              <li>der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, die betroffene Person sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigt, oder</li>
              <li>die betroffene Person Widerspruch gegen die Verarbeitung gemäß Artikel 21 Absatz 1 eingelegt hat, solange noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen Person überwiegen</li>
            </ol>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>Schutz personenbezogener Daten durch technische und organisatorische Maßnahmen</span>
            </p>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Wir setzen auf Basis des vom Gesetz geforderten risikoorientierten Ansatzes technische und organisatorische Maßnahmen ein, um personenbezogene Daten gegen zufällige oder vorsätzliche Manipulationen, gegen Verlust, Zerstörung oder den Zugriff Unberechtigter zu schützen. Unsere Sicherheitsmaßnahmen werden unter anderem entsprechend der technologischen Entwicklung fortlaufend verbessert, sie werden getestet und regelmäßig durch interne wie externe Stellen überprüft.
              </span>
            </p>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>Rechte der betroffenen Person</span>
            </p>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie betroffene Person i. S. d. DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:
              </span><br /><br />
              <b>1. Auskunftsrecht</b><br /><br />
              <span>
                Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden. Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft verlangen:
              </span>
            </p>
            <ol className="list-decimal pl-5 mt-4 text-base leading-6 text-black w-full">
              <li>die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
              <li>die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
              <li>die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;</li>
              <li>die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;</li>
              <li>das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;</li>
              <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
              <li>alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;</li>
              <li>das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.</li>
            </ol>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
              </span><br /><br />
              <b>2. Recht auf Berichtigung</b><br /><br />
              <span>
                Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
              </span><br /><br />
              <b>3. Recht auf Einschränkung der Verarbeitung</b><br /><br />
              <span>Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen:</span>
            </p>
            <ol className="list-decimal pl-5 mt-4 text-base leading-6 text-black w-full">
              <li>wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;</li>
              <li>die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;</li>
              <li>der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder</li>
              <li>wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.</li>
            </ol>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden. Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
              </span><br /><br />
              <b>4. Recht auf Löschung</b><br /><br />
              <span>a) Löschungspflicht</span><br />
              <span>
                Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
              </span>
            </p>
            <ol className="list-decimal pl-5 mt-4 text-base leading-6 text-black w-full">
              <li>Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</li>
              <li>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 Satz 1 Buchst. a oder Art. 9 Abs. 2 Buchst. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.</li>
              <li>Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.</li>
              <li>Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
              <li>Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.</li>
              <li>Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.</li>
            </ol>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>b) Information an Dritte</span><br />
              <span>
                Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.
              </span><br /><br />
              <span>c) Ausnahmen</span><br />
              <span>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</span>
            </p>
            <ol className="list-decimal pl-5 mt-4 text-base leading-6 text-black w-full">
              <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
              <li>zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;</li>
              <li>aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 Buchst. h und i sowie Art. 9 Abs. 3 DSGVO;</li>
              <li>für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder</li>
              <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
            </ol>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>Recht auf Unterrichtung</span><br />
              <span>
                Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.
              </span><br /><br />
              <b>6. Recht auf Datenübertragbarkeit</b><br />
              <span>
                Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
              </span>
            </p>
            <ol className="list-decimal pl-5 mt-4 text-base leading-6 text-black w-full">
              <li>die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 Satz 1 Buchst. a DSGVO oder Art. 9 Abs. 2 Buchst. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 Satz 1 Buchst. b DSGVO beruht und</li>
              <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
            </ol>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden. Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
              </span><br /><br />
              <b>7. Widerspruchsrecht</b><br />
              <span>
                Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 Satz 1 Buchst. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
              </span><br /><br />
              <span>
                Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
              </span><br /><br />
              <span>
                Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
              </span><br /><br />
              <span>Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.</span><br /><br />
              <span>Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.</span><br /><br />
              <b>8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</b><br />
              <span>
                Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
              </span><br /><br />
              <b>9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling</b><br />
              <span>
                Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtlicher Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung
              </span>
            </p>
            <ol className="list-decimal pl-5 mt-4 text-base leading-6 text-black w-full">
              <li>für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,</li>
              <li>aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder</li>
              <li>mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
            </ol>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 Buchst. a oder g gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden. Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
              </span><br /><br />
              <b>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</b><br />
              <span>
                Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt. Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
              </span>
            </p>
            <p className="mt-4 text-base leading-6 text-black w-full"></p>
            <h3 className="text-xl leading-7 text-black w-full mt-4">Betrieblicher Datenschutzbeauftragter</h3>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <strong>HB E-Commerce Rechtsanwaltsgesellschaft mbH</strong><br />
              <span>Kohlgartenstraße 11-13</span><br />
              <span>04315 Leipzig</span><br />
              <span>E-Mail: litfax@datenschutz.hb-ecommerce.eu</span><br />
              <span>Tel.: +49 341 92 65 92 00</span><br />
              <span>Fax: +49 341 26 18 06 88</span><br /><br />
              <a href="https://my-litfax.de/makepage.php?p=contact&amp;" target="_blank"><span style={{ color: "#0067b1" }}>Webformular zur Kontaktaufnahme</span></a>
            </p>
            <p className="mt-4 text-base leading-6 text-black w-full"></p>
            <h3 className="text-xl leading-7 text-black w-full mt-4">Webauftritt und Logfiles</h3>
            <h3 className="text-xl leading-7 text-black w-full mt-4">Beschreibung und Umfang der Datenverarbeitung</h3>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Bei jeder Nutzung unseres Webauftritts werden Daten zwischen dem Client (z. B. Ihrem Web-Browser) und dem Server (unser Webauftritt) ausgetauscht. Auf unserem Server werden hierfür die nachfolgenden Informationen gespeichert:
              </span>
            </p>
            <ul className="list-disc pl-5 mt-4 text-base leading-6 text-black w-full">
              <li>Datum und Uhrzeit des Zugriffs</li>
              <li>Typ und Version Ihres Internet-Browsers</li>
              <li>Typ und Version des Betriebssystems</li>
              <li>volle IP-Adresse des Nutzers</li>
              <li>anonymisierte IP-Adresse des Nutzers</li>
              <li>den Internet-Service-Provider des Nutzers</li>
              <li>aufgerufene Seiten des Web-Auftritts</li>
              <li>Datenmenge der angeforderten Inhalte unserer Webseite</li>
              <li>die zuvor besuchte Seite (Referrer URL)</li>
            </ul>
            <h3 className="text-xl leading-7 text-black w-full mt-4">Zweck</h3>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>Diese Informationen werden von uns ausschließlich für die nachfolgenden Zwecke genutzt:</span>
            </p>
            <ul className="list-disc pl-5 mt-4 text-base leading-6 text-black w-full">
              <li>Erstellung von Nutzungsstatistiken</li>
              <li>Erstellung von sonstigen Statistiken</li>
              <li>Optimierung unserer Inhalte</li>
              <li>Sicherstellung des Betriebs oder der Betriebssicherheit</li>
              <li>Analyse bei Verdacht auf eine rechtswidrige Nutzung unserer Webseite</li>
            </ul>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 Satz 1 Buchst. f DSGVO.</span><br /><br />
              <b>Rechtsgrundlage</b><br />
              <span>Rechtsgrundlage für die vorübergehende Speicherung dieser Daten und der zugehörigen Logfiles ist Art. 6 Abs. 1 Satz 1 Buchst. f DSGVO.</span><br /><br />
              <b>Dauer der Speicherung</b><br />
              <span>Die Daten werden gelöscht, sobald sie für die Erreichung des jeweiligen Zweckes nicht mehr erforderlich sind. Im Falle der Logfiles ist dies spätestens nach 180 Tagen der Fall.</span><br /><br />
              <b>Widerruf, Widerspruch und Ende der Verarbeitungen</b><br />
              <span>
                Der Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles kann effektiv durch Verlassen der Internetseite widersprochen werden. Soweit personenbezogene Daten bereits gespeichert sind, richten Sie Ihren etwaigen Widerspruch bitte an die eingangs genannten Kontaktdaten.
              </span>
            </p>
            <h3 className="text-xl leading-7 text-black w-full mt-4">Technisch notwendige Cookies</h3>
            <h3 className="text-xl leading-7 text-black w-full mt-4">Beschreibung und Umfang der Datenverarbeitung</h3>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Unsere Internetseite verwendet Cookies. Cookies sind Textdateien, die im Internet-Browser bzw. vom Internet-Browser auf dem Endgerät des Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Endgerät des Nutzers gespeichert werden. Dieses Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht.
              </span><br /><br />
              <span>
                Wir setzen Cookies ein, um unsere Internetseite nutzerfreundlicher zu gestalten. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann. Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet. Folgende Daten können in den Cookies gespeichert und übermittelt werden:
              </span>
            </p>
            <ul className="list-disc pl-5 mt-4 text-base leading-6 text-black w-full">
              <li>Spracheinstellungen</li>
              <li>Log-In-Informationen</li>
              <li>Inanspruchnahme von Website-Funktionen</li>
            </ul>
            <h3 className="text-xl leading-7 text-black w-full mt-4">Zweck</h3>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird. Für folgende Anwendungen benötigen wir Cookies:
              </span>
            </p>
            <ul className="list-disc pl-5 mt-4 text-base leading-6 text-black w-full">
              <li>Übernahme von Spracheinstellungen</li>
              <li>Merken von Suchbegriffen</li>
              <li>Log-In-Informationen</li>
            </ul>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen verwendet.</span><br /><br />
              <b>Rechtsgrundlage</b><br />
              <span>Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung technisch notwendiger Cookies ist Art. 6 Abs. 1 Satz 1 Buchst. f DSGVO.</span><br /><br />
              <b>Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit</b><br />
              <span>
                Cookies werden auf dem Endgerät des Nutzers gespeichert und von diesem an unserer Seite übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internet-Browser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.
              </span><br /><br />
              <span>Die Übermittlung von Flash-Cookies lässt sich nicht über die Einstellungen des Browsers, jedoch durch Änderungen der Einstellung des Flash Players unterbinden.</span>
            </p>
            <h3 className="text-xl leading-7 text-black w-full mt-4">Weitere Cookies (Analyse- und Werbe-Cookies)</h3>
            <h3 className="text-xl leading-7 text-black w-full mt-4">Beschreibung und Umfang der Datenverarbeitung</h3>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Neben den technisch notwendigen Cookies setzen wir auf unserer Website optionale Cookies (Analyse- und Werbe-Cookies) ein. Analyse-Cookies sind notwendig, um das Surfverhalten der Nutzer auf unserer Website zu analysieren. Werbe-Cookies sind notwendig, um den Nutzern der Website für sie relevante, interessenbasierte Werbung anzuzeigen. Der Einsatz der optionalen Cookies erfolgt nur mit Einwilligung der Nutzer (Art. 6 Abs. 1 Satz 1 Buchst. a DSGVO).
              </span><br /><br />
              <span>Zu den bei Nutzung unserer Webseite erhobenen Daten zählen:</span>
            </p>
            <ul className="list-disc pl-5 mt-4 text-base leading-6 text-black w-full">
              <li>die IP-Adresse in anonymisierter Form (wird nur zur Feststellung des Orts des Zugriffs verwendet)</li>
              <li>die IP-Adresse in vollständiger Form</li>
              <li>die aufgerufene Webseite</li>
              <li>die Webseite, von der der Nutzer auf die aufgerufene Webseite gelangt ist (Referrer)</li>
              <li>die Unterseiten, die von der aufgerufenen Webseite aufgerufen werden</li>
              <li>die Verweildauer auf der Webseite</li>
              <li>die Häufigkeit des Aufrufs der Webseite</li>
              <li>das Klickverhalten auf unserer Website</li>
            </ul>
            <h3 className="text-xl leading-7 text-black w-full mt-4">Zweck</h3>
            <p className="mt-4 text-base leading-6 text-black w-full">
              <span>
                Die Verarbeitung der personenbezogenen Daten der Nutzer ermöglicht uns eine Analyse des Surfverhaltens unserer Nutzer. Wir sind durch Auswertung der gewonnenen Daten in der Lage, Informationen über die Nutzung der einzelnen Komponenten unserer Website zusammenzustellen. Dies hilft uns dabei, unsere Website und deren Nutzerfreundlichkeit stetig zu verbessern. Darin liegt auch unser berechtigtes Interesse in der Verarbeitung der Daten nach Art. 6 Abs. 1 Satz 1 Buchst. f DSGVO. Durch die Anonymisierung der IP-Adresse wird dem Interesse der Nutzer am Schutz ihrer personenbezogenen Daten Rechnung getragen.
              </span><br /><br />
              <b>Rechtsgrundlage</b><br />
              <span>Rechtsgrundlage für die Verarbeitung personenbezogener Daten der Nutzer ist die Einwilligung des Nutzers nach Art. 6 Abs. 1 Satz 1 Buchst. a DSGVO.</span><br /><br />
              <b>Dauer der Speicherung</b><br />
              <span>Die Daten werden gelöscht, sobald sie für unsere Aufzeichnungszwecke nicht mehr benötigt werden.</span><br /><br />
              <b>Widerruf, Widerspruch und Ende der Verarbeitungen</b><br />
              <span>
                Cookies werden auf dem Endgerät des Nutzers gespeichert und von diesem an unserer Seite übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem Internet-Browser können Sie die Übertragung von Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
