import React from "react";
import { Player } from '@lottiefiles/react-lottie-player';

interface SocialMediaItemProps {
  src: string;
  url: string;
}

/**
 * SocialMediaItem component
 * @param {Object} props - The props object
 * @param {string} props.src - The source URL for the social media icon
 * @param {string} props.url - The URL for the social media link
 * @returns {JSX.Element} - The rendered SocialMediaItem component
 */
const SocialMediaItem: React.FC<SocialMediaItemProps> = ({ src, url }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <img
        loading="lazy"
        src={src}
        className="shrink-0 w-[50px] h-[50px]"
        style={{ marginTop: '25px' }} // Add this line to set the top margin
      />
    </a>
  );
};

/**
 * LinkItem component for rendering individual link items
 * @param {Object} props - The props object
 * @param {string} props.text - The text for the link item
 * @param {string} props.href - The URL for the link item
 * @returns {JSX.Element} The rendered LinkItem component
 */
interface LinkItemProps {
  text: string;
  href: string;
}

const LinkItem: React.FC<LinkItemProps> = ({ text, href }) => {
  return (
    <a href={href} className="my-auto leading-[135%] text-white">
      {text}
    </a>
  );
};

/**
 * Separator component for rendering separators between link items
 * @param {Object} props - The props object
 * @param {string} props.text - The text for the separator
 * @returns {JSX.Element} The rendered Separator component
 */
interface SeparatorProps {
  text: string;
}

const Separator: React.FC<SeparatorProps> = ({ text }) => {
  return <div className="text-white">{text}</div>;
};

const Footer: React.FC = () => {
  const socialMediaItems = [
    { src: "facebook.jpg", url: "https://www.facebook.com/litfax" },
    { src: "instagram.jpg", url: "https://www.instagram.com/litfax_gmbh/" },
    { src: "linkedin.jpg", url: "https://www.linkedin.com/company/litfax-gmbh/" },
    { src: "xing.jpg", url: "https://www.xing.com/pages/litfaxgmbh-verlagfurbanken" },
    { src: "email.jpg", url: "mailto:info@my-litfax.de" },
    { src: "whatsapp.jpg", url: "https://www.whatsapp.com/channel/0029Vaddkey0lwgmo7DuDH1w" },
  ];

  const linkItems = [
    { text: "Kontakt", href: "/contact" },
    { text: "AGB", href: "/agb" },
    { text: "Datenschutz", href: "/privacy" },
    { text: "Impressum", href: "/imprint" },
  ];

  return (
    <div className="flex flex-col items-center w-full bg-blue-300 text-white">
      <section className="flex justify-center items-start w-full bg-white max-md:px-5 max-md:max-w-full p-0 m-0">
        <Player
          autoplay
          loop
          src="/footer.json" // Path to your Lottie animation file
          style={{ height: '300px', width: '100%' }}
        />
      </section>
      <div className="flex justify-center items-center px-16 pb-14 w-full text-xs text-center text-white bg-blue-300 max-md:px-5 max-md:max-w-full mt-0">
        <div className="flex flex-col max-w-full w-[822px]">
            <div className="w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0 justify-center items-start pb-6">
                    <div className="flex gap-2 justify-center items-start">
                    {socialMediaItems.map((item, index) => (
                        <SocialMediaItem key={index} src={item.src} url={item.url} />
                    ))}
                    </div>
                </div>
            </div>
          <div className="flex justify-center gap-3.5 leading-[133%] text-white max-md:flex-wrap">
            {linkItems.map((item, index) => (
              <React.Fragment key={index}>
                <LinkItem text={item.text} href={item.href} />
                {index !== linkItems.length - 1 && <Separator text="|" />}
              </React.Fragment>
            ))}
          </div>
          <div className="self-center mt-2 text-xs font-medium leading-4 text-white max-md:max-w-full">
            litfax® GmbH - Verlag für Banken, Darßer Bogen 3, 13088 Berlin | Vertreten durch: Peter Junge
          </div>
          <div className="mt-2.5 leading-[138%] text-white max-md:max-w-full">
            Alle Rechte vorbehalten: Alle auf dieser Internetpräsenz verwendeten Texte, Fotos und grafischen Gestaltungen sind urheberrechtlich geschützt.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
