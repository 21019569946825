import React, { useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as THREE from 'three';

// Button Component
const Button: React.FC<{ text: string; onClick: () => void }> = ({
  text,
  onClick,
}) => {
  return (
    <button
      className="justify-center px-7 py-3.5 mt-12 text-lg leading-6 text-white rounded border border-solid bg-cyan-950 border-cyan-950 max-md:px-5 max-md:mt-10 max-md:max-w-full"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

// Model Component
const Model: React.FC = () => {
  const ref = useRef<THREE.Group | null>(null);

  useEffect(() => {
    const loader = new FBXLoader();
    loader.load(
      '/pvc.fbx',
      (object) => {
        console.log('Model loaded:', object);
        object.position.set(-5, -15, 0); // Center the card vertically
        object.rotation.set(0, Math.PI / 4, 0); // Adjust rotation for a better view
        object.scale.set(0.1, 0.1, 0.1); // Further scale down to fit the view
        if (ref.current) {
          ref.current.add(object);
        }
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      (error) => {
        console.error('An error happened', error);
      }
    );
  }, []);

  return <group ref={ref} />;
};

// Main Component
const Example08: React.FC = () => {
  const handleButtonClick = () => {
    window.location.href = 'https://my-litfax.de/Digitale_Visitenkarten_NFC-Digitale_NFC_Visitenkarten-wg.htm';
  };

  return (
    <section className="flex justify-center items-center px-16 py-5 font-medium text-center text-black bg-stone-50 max-md:px-5" id="example08">
      <div className="flex flex-col items-center mt-16 max-w-full w-[900px] max-md:mt-10">
        <h1 className="text-3xl font-extralight leading-9 max-md:max-w-full">
          Bei jeder NFC Visitenkarte vorkonfiguriert
        </h1>
        <h2 className="mt-2 text-2xl leading-8 uppercase max-md:max-w-full">
          IHR PERSÖNLICHES UND DIGITALES PROFIL
        </h2>
        <Canvas
          className="self-stretch mt-12 w-full aspect-[0.81] max-md:mt-10 max-md:max-w-full"
          camera={{ position: [0, 20, 80], fov: 30 }} // Moved camera further back to capture full card
          style={{ width: '100%', height: '500px' }} // Ensure Canvas has a defined height
        >
          <ambientLight />
          <pointLight position={[10, 10, 10]} />
          <Model />
          <OrbitControls 
            enableZoom={true} 
            enablePan={true} 
            target={[0, 0, 0]} // Ensure camera is focused on the model
            onChange={() => console.log('OrbitControls changed')}
          />
        </Canvas>
        <Button
          text="Hier klicken und NFC Visitenkarte konfigurieren"
          onClick={handleButtonClick}
        />
        <a
          href="https://my-litfax.de/makepage.php?p=article_detail&aid=278071"
          className="mt-4 text-base leading-6 underline text-cyan-950"
        >
          oder Individuallösung anfragen
        </a>
      </div>
    </section>
  );
};

export default Example08;
