import React from 'react';

/**
 * TextWithIcon component renders an icon and text in a flex container.
 * @param {Object} props - The props object.
 * @param {React.ReactNode} props.icon - The icon to display.
 * @param {string} props.text - The text to display.
 * @returns {JSX.Element} - The rendered TextWithIcon component.
 */
const TextWithIcon: React.FC<{ icon: React.ReactNode; text: string }> = ({
  icon,
  text,
}) => (
  <div className="flex gap-3 mt-4 max-md:flex-wrap">
    <div className="text-xs font-black leading-6 text-blue-300">{icon}</div>
    <div className="flex-auto text-base leading-6 text-black max-md:max-w-full">
      {text}
    </div>
  </div>
);

/**
 * Section03 is the main component that renders the entire page.
 * @returns {JSX.Element} - The rendered Section03.
 */
const Details03: React.FC = () => {
  const textWithIconData = [
    {
      icon: "✔",
      text: "Trennung von privaten und geschäftlichen Kontaktdaten",
    },
    {
      icon: "✔",
      text: "Mehrsprachigkeit der vCard-Profile (Deutsch und Englisch)",
    },
    {
      icon: "✔",
      text: "Spezifische Angaben zum Job, z. B. Stelle und Position",
    },
    {
      icon: "✔",
      text: "Link zur Website, Social Media Profilen oder anderen Medien",
    },
  ];

  return (
    <div className="flex justify-center items-center px-16 py-20 bg-white max-md:px-5" id="details03">
      <div className="max-w-full w-[1200px]">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
              <h1 className="text-3xl font-medium leading-9 text-black max-md:max-w-full">
                NFC Visitenkarten <br />
                <span>mit eigenem digitalen Profil</span>
              </h1>
              <p className="mt-5 text-base leading-6 text-black max-md:max-w-full">
                Die NFC Visitenkarten von my-litfax vereinen traditionelle Eleganz mit modernster Technologie. Neben der klassischen
                physischen Karte im <span className="font-medium">Scheckkartenformat</span> verfügen sie über einen integrierten <span className="font-medium">NFC-Chip</span>, der digitale Informationen
                blitzschnell zugänglich macht. Auf den Chip ist ein Link zu Ihrem <span className="font-medium">digitalen vCard-Profil</span> integriert, der mit jedem NFC-
                fähigen Smartphone problemlos geöffnet werden kann. Unsere <span className="font-medium">Papier Visitenkarten</span> bedienen sich hier der Technik mit
                einem <span className="font-medium">QR-Code</span>.
              </p>
              <p className="mt-8 text-base font-medium leading-6 text-black max-md:max-w-full">
                Keine Karte? Kein Problem! Du findest trotzdem den Weg zu ihrem Profil.
              </p>
              <TextWithIcon
                icon="✔"
                text="QR-Code scannen lassen, z. B. auf Flyern, Website oder anderen Medien"
              />
              <TextWithIcon
                icon="✔"
                text="Individuellen Link zum vCard-Profil in Ihrer E-Mail-Signatur hinterlegen"
              />
              <div className="flex gap-1.5 self-start mt-5 text-cyan-950">
                <p className="grow text-base leading-6 text-black">
                  Hier geht's zum{" "}
                </p>
                <a
                  href="#slider04"
                  className="flex-auto text-base font-medium leading-6 underline"
                >
                  Beispiel vCard-Profil
                </a>
                <span className="text-sm leading-6"></span>
              </div>
              <h2 className="mt-11 text-xl font-medium leading-7 text-black max-md:mt-10 max-md:max-w-full">
                Mehr als nur eine klassische Standard Visitenkarte
              </h2>
              <p className="mt-6 text-base leading-6 text-black max-md:max-w-full">
                Mit einer NFC Visitenkarte öffnet sich eine Welt voller Möglichkeiten, die weit über das Format einer herkömmlichen
                Papiervisitenkarte hinausgeht. Präsentieren Sie Ihr Logo, Ihr Profilbild und all Ihre Kontaktdaten mit Leichtigkeit. Und
                das Beste: Verlinken Sie direkt zu Ihren Social Media Profilen, um sich noch besser zu vernetzen. NFC Visitenkarten –
                der Schlüssel zu einer modernen und effektiven Kontaktaufnahme.
              </p>
              {textWithIconData.map((item, index) => (
                <TextWithIcon key={index} icon={item.icon} text={item.text} />
              ))}
              <a
                href="https://my-litfax.de/Digitale_Visitenkarten_NFC-Digitale_NFC_Visitenkarten-wg.htm"
                className="justify-center self-start px-6 py-3.5 mt-9 text-lg font-medium leading-6 text-center text-white rounded border border-solid bg-cyan-950 border-cyan-950 max-md:px-5"
              >
                Digitale Visitenkarte konfigurieren
              </a>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-center my-auto text-xs leading-4 text-center text-neutral-300 max-md:mt-10 max-md:max-w-full w-[550px] h-[550px]">
              <img loading="lazy" src="Mockup_mit_QR.jpg" alt="NFC Visitenkarte" className="w-full h-auto max-md:max-w-full max-h-full" />
              <p className="mt-5">Hier scannen um zum Beispiel vCard-Profil zu gelangen</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details03;
