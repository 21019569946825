import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Hero01 from './Hero01';
import Checkmarks02 from './Checkmarks02';
import Details03 from './Details03';
import Slider04 from './Slider04';
import ThreeSteps05 from './ThreeSteps05';
import Individual06 from './Individual06';
import Benefits07 from './Benefits07';
import Example08 from './Example08';
import Slider09 from './Slider09';
import CostOverviewHeading10 from './CostOverviewHeading10';
import CostOverview11 from './CostOverview11';
import Footer from './Footer';
import ContactPage from './ContactPage';
import AGBPage from './AGBPage';
import PrivacyPage from './PrivacyPage';
import ImprintPage from './ImprintPage';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <header>
          <Header />
        </header>
        <main>
          <Routes>
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/agb" element={<AGBPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/imprint" element={<ImprintPage />} />
            <Route path="/" element={
              <>
                <Hero01 />
                <Checkmarks02 />
                <Details03 />
                <Slider04 />
                <ThreeSteps05 />
                <Individual06 />
                <Benefits07 />
                <Example08 />
                <Slider09 />
                <CostOverviewHeading10 />
                <CostOverview11 />
              </>
            } />
          </Routes>
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </Router>
  );
};

export default App;
