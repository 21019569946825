import React from 'react';

/**
 * Represents a clickable dot component.
 * @param {Object} props - The component props.
 * @param {string} props.color - The background color class of the dot.
 * @param {string} props.size - The size class of the dot.
 * @param {Function} props.onClick - The click event handler.
 * @returns {JSX.Element} The rendered dot component.
 */
const Dot: React.FC<{
  color: string;
  size: string;
  onClick: () => void;
}> = ({ color, size, onClick }) => (
  <button
    className={`shrink-0 ${size} ${color} rounded-2xl`}
    onClick={onClick}
  />
);

/**
 * Represents the main component.
 * @returns {JSX.Element} The rendered main component.
 */
const Slider09: React.FC = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  /**
   * Handles the click event on a dot.
   * @param {number} index - The index of the clicked dot.
   */
  const handleDotClick = (index: number) => {
    setActiveIndex(index);
  };

  const images = [
    "karte-und-geraete01.png",
    "karte-und-geraete02.png",
    "karte-und-geraete03.png",
    "karte-und-geraete04.png",
    "karte-und-geraete05.png",
  ];

  return (
    <section className="flex justify-center items-center px-16 py-20 bg-stone-50 max-md:px-5">
      <div className="flex flex-col items-center max-w-full w-[1000px]">
      <h1 className="text-3xl font-extralight leading-9 max-md:max-w-full">
          Bei jeder NFC Visitenkarte vorkonfiguriert
        </h1>
        <h2 className="mt-2 text-2xl leading-8 uppercase max-md:max-w-full">
          IHR PERSÖNLICHES UND DIGITALES PROFIL
        </h2>
        <img src={images[activeIndex]} alt={`Slide ${activeIndex + 1}`} className="mt-10" />
        <div className="flex gap-2.5 mt-10">
          {images.map((_, index) => (
            <Dot
              key={index}
              color={index === activeIndex ? "bg-blue-300" : "bg-gray-400"}
              size={index === activeIndex ? "w-[25px] h-2.5" : "w-2.5 h-2.5"}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Slider09;
