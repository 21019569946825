import React from 'react';

/**
 * A feature item component that displays an image and a title.
 * @param {Object} props - The component props.
 * @param {string} props.imageSrc - The URL of the image to display.
 * @param {string} props.title - The title text to display below the image.
 */
const FeatureItem: React.FC<{ imageSrc: string; title: string }> = ({
  imageSrc,
  title,
}) => (
  <div className="flex flex-col max-w-full w-[248px]">
    <img src={imageSrc} alt={title} className="self-center aspect-square w-[50px]" />
    <div className="mt-2.5">{title}</div>
  </div>
);

/**
 * The main component that renders the feature section.
 */
const Checkmarks02: React.FC = () => {
  const features = [
    {
      imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e873a0660659d5db16f9049fc81c879e94cec766bfcbdcc168fa92dfb8e51e9f?apiKey=a56df2c6eebb45c19a07541faf6fbf04&",
      title: "Daten in Echtzeit aktualisieren",
    },
    {
      imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/8018a3893fa176f1956fa45a603414a9bd6068fcd6bc85284d71dbf64331988a?apiKey=a56df2c6eebb45c19a07541faf6fbf04&",
      title: "Auslesen ohne App",
    },
    {
      imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/8018a3893fa176f1956fa45a603414a9bd6068fcd6bc85284d71dbf64331988a?apiKey=a56df2c6eebb45c19a07541faf6fbf04&",
      title: "Nachhaltig",
    }
  ];

  return (
    <div className="flex gap-5 justify-end bg-blue-300 max-md:flex-wrap max-md:pl-5">
      <div className="flex-auto max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full"
            >
              <div className="flex grow justify-center items-center px-16 py-8 text-lg leading-6 text-center text-white border-l border-gray-200 border-solid max-md:px-5 max-md:mt-8 max-md:max-w-full">
                <FeatureItem
                  imageSrc={feature.imageSrc}
                  title={feature.title}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Checkmarks02;