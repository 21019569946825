import React from 'react';

/**
 * ThreeSteps05 is the main component that renders the entire page.
 * @returns {JSX.Element} - The rendered ThreeSteps05.
 */
const ThreeSteps05: React.FC = () => {
  return (
    <div className="flex justify-center items-center px-16 py-20 bg-white max-md:px-5">
      <div className="max-w-full w-[1200px]">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full justify-center">
              <h1 className="text-3xl font-medium leading-9 text-black max-md:max-w-full">
                1. Produkt auswählen
              </h1>
              <p className="mt-5 text-base leading-6 text-black max-md:max-w-full">
                Rufen Sie <a href="https://my-litfax.de/" className="flex-auto text-base font-medium leading-6 underline">my-litfax.de</a> auf und wählen Sie aus hunderten hochwertigen Produkten. Wenn Sie Ihr Produkt gefunden haben und es personalisieren wollen öffnet sich daraufhin der Editor in dem Sie Anpassungen vornehmen können.
              </p>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-center my-auto text-xs leading-4 text-center text-neutral-300 max-md:mt-10 max-md:max-w-full w-[500px] h-[500px]">
              <img loading="lazy" src="Step1.jpg" alt="NFC Visitenkarte" className="w-full h-auto max-md:max-w-full max-h-full" />
            </div>
          </div>
        </div>
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full justify-center">
              <h1 className="text-3xl font-medium leading-9 text-black max-md:max-w-full">
              2. Produkt konfigurieren
              </h1>
              <p className="mt-5 text-base leading-6 text-black max-md:max-w-full">
                Laden Sie Ihr Bild oder Logo hoch und platzieren es. Geben Sie Ihren Wunschtext in den Feldern ein, diesen können Sie übrigens auch anpassen! Am Ende erzeugen Sie eine verbindliche Vorschau und bestätigen diese.
              </p>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-center my-auto text-xs leading-4 text-center text-neutral-300 max-md:mt-10 max-md:max-w-full w-[500px] h-[500px]">
              <img loading="lazy" src="Step2.jpg" alt="NFC Visitenkarte" className="w-full h-auto max-md:max-w-full max-h-full" />
            </div>
          </div>
        </div>
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full justify-center">
              <h1 className="text-3xl font-medium leading-9 text-black max-md:max-w-full">
                3. Produkt bestellen
              </h1>
              <p className="mt-5 text-base leading-6 text-black max-md:max-w-full">
                Rufen Sie <a href="https://my-litfax.de/" className="flex-auto text-base font-medium leading-6 underline">my-litfax.de</a> auf und wählen Sie aus hunderten hochwertigen Produkten. Wenn Sie Ihr Produkt gefunden haben und es personalisieren wollen öffnet sich daraufhin der Editor in dem Sie Anpassungen vornehmen können.
              </p>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-center my-auto text-xs leading-4 text-center text-neutral-300 max-md:mt-10 max-md:max-w-full w-[500px] h-[500px]">
              <img loading="lazy" src="Step3.jpg" alt="NFC Visitenkarte" className="w-full h-auto max-md:max-w-full max-h-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeSteps05;
