import React from "react";

const ContactPage: React.FC = () => {
  const emailUser = "info";
  const emailDomain = "litfax-bv.de";
  const email = `${emailUser}@${emailDomain}`;

  return (
    <div className="flex justify-center items-center px-16 py-14 bg-stone-50 max-md:px-5">
      <div className="max-w-full w-[1200px]">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-full max-md:ml-0">
            <h2 className="text-2xl leading-8 text-black w-full">
              Kontakt
            </h2>
            <p className="mt-4 text-base leading-6 text-black w-full">
              Sie haben Fragen zu unserem Shop oder der Abwicklung Ihrer Bestellung?
            </p>
            <p className="mt-4 text-base leading-6 text-black w-full">
              Wir freuen uns auf Ihre Kontaktaufnahme: <a href={`mailto:${email}`} className="text-blue-600">{email}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
