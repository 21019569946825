import React from 'react';
import ReactPlayer from 'react-player';

/**
 * BenefitItem component displays a single benefit item with an icon and text.
 * @param {Object} props - The props object.
 * @param {string} props.text - The text content of the benefit item.
 * @returns {JSX.Element} - The rendered BenefitItem component.
 */
const BenefitItem: React.FC<{ text: string }> = ({ text }) => (
  <div className="flex gap-3.5 mt-4 max-md:flex-wrap">
    <div className="text-sm font-black leading-6 text-blue-300"></div>
    <div className="flex-auto text-base leading-6 text-black max-md:max-w-full">
      {text}
    </div>
  </div>
);

/**
 * Individual06 is the main component that renders the entire section.
 * @returns {JSX.Element} - The rendered Individual06.
 */
const Individual06: React.FC = () => {
  const benefits = [
    "✔ CI-Paket: Design und Farben passend zu Ihrem Firma",
    "✔ Wow-Effekt: Edle Materialen und Veredelungstechniken",
    "✔ Business-Account: Pflege der Karten über zentrale Verwaltung",
    "✔ QR-Code-Option: Profil-Kompatibilität für nicht NFC-fähige Smartphones",
    "✔ Eigene Profillinks: Nutzen Sie Ihre eigenen Links/Website-Profile",
  ];

  const VideoPlayer = () => (
    <ReactPlayer
      url="NFC_Karten_Video_Final.mp4"
      width="100%"
      height="auto"
      controls={true}
    />
  );

  return (
    <section className="flex justify-center items-center px-16 py-20 bg-stone-50 max-md:px-5">
      <div className="max-w-full w-[1191px]">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <VideoPlayer />
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
              <h2 className="text-3xl leading-9 text-black max-md:max-w-full">
                Mehr Flexibilität und Präsenz: <br />
                <span className="font-medium">
                  Personalisierte NFC Visitenkarten
                </span>
              </h2>
              <p className="mt-10 text-base leading-6 text-black max-md:max-w-full">
                Möchten Sie Ihr Team mit eleganten digitalen Visitenkarten
                ausstatten? Wählen Sie die Individualanfrage und lassen Sie uns
                gemeinsam Ihre Vision verwirklichen. Unser Expertenteam steht
                bereit, um Sie zu beraten und zu unterstützen.
              </p>
              <h2 className="text-3xl leading-9 text-black max-md:max-w-full">
                Hochwertige Materialien und Veredelungen
              </h2>
              {benefits.map((benefit, index) => (
                <BenefitItem key={index} text={benefit} />
              ))}
              <a href="https://my-litfax.de/makepage.php?p=article_detail&aid=278071&" target="_blank" rel="noopener noreferrer">
                <button className="flex gap-2.5 justify-center self-start px-6 py-3.5 mt-5 text-lg text-center text-white rounded border border-solid bg-cyan-950 border-cyan-950 max-md:px-5">
                  <span className="grow font-medium leading-[148%]">
                    Individuallösung anfragen
                  </span>
                  <span className="leading-[100%]"></span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Individual06;
