import React from 'react';
import PriceRow from './PriceRow';

type PriceTableProps = {
  title: string;
  subtitle: string;
  priceData: { quantity: number; pricePerCard: string }[];
};

const PriceTable: React.FC<PriceTableProps> = ({ title, subtitle, priceData }) => (
  <section className="flex flex-col px-5 text-sm font-medium max-w-[587px] text-cyan-950 w-full">
    <h1 className="self-center text-xl leading-6 text-center text-black">{title}</h1>
    <p className="self-center m-5 text-xs leading-4 text-center text-neutral-300">
      {subtitle}
    </p>
    <div className="flex w-full text-lg text-white max-md:flex-col">
      <div className="flex-1 px-3 py-3.5 bg-blue-300 border border-blue-300 border-solid leading-[138%] max-md:pr-5 max-md:w-full">
        Menge
      </div>
      <div className="flex-1 px-6 py-4 text-right bg-blue-300 border-t border-r border-b border-blue-300 border-solid leading-[143%] max-md:pl-5 max-md:w-full">
        Preis je Karte (netto)
      </div>
    </div>
    {priceData.map((item, index) => (
      <PriceRow key={index} quantity={item.quantity} pricePerCard={item.pricePerCard} />
    ))}
  </section>
);

export default PriceTable;
