import React from 'react';
import TabbedComponent from './TabbedComponent';

const Hero01 = () => {
    /**
     * Handles the click event for the individual request button.
     */
    const handleIndividualRequest = () => {
      console.log("Individual request clicked");
    };
  
    /**
     * Handles the click event for the single order button.
     */
    const handleSingleOrder = () => {
      console.log("Single order clicked");
    };
  
    /**
     * Handles the click event for the configure card button.
     */
    const handleConfigureCard = () => {
      console.log("Configure card clicked");
    };
  
    return (
      <div className="flex justify-center items-center px-16 py-14 bg-stone-50 max-md:px-5">
        <div className="max-w-full w-[1200px]">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:w-full">
              <div className="flex flex-col text-center max-md:mt-10 max-md:max-w-full">
                <div className="flex flex-col justify-center p-1 text-xs font-medium leading-5 text-white uppercase whitespace-nowrap rounded border-4 border-white border-solid shadow-sm bg-white bg-opacity-0 max-md:max-w-[500px] max-w-[500px]">
                  <div className="flex overflow-hidden relative flex-col items-start min-h-[413px] max-md:max-w-full">
                    <img
                      src='/001_Anwendung_klein.gif'
                      alt="Digital business card design"
                      style={{ objectFit: 'cover' }}
                      width={500}
                      height={500}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <section className="flex flex-col grow mt-3 max-md:mt-10 max-md:max-w-full">
                <h2 className="text-2xl leading-8 text-black max-md:max-w-full">
                Digi-vCards | my-litfax – Digitale Visitenkarten
                </h2>
                <p className="mt-4 text-base leading-6 text-black max-md:max-w-full">
                  Ein Händedruck, ein Lächeln und Ihre digitale Visitenkarte – so bleibt Ihr Kontakt unvergesslich. Mit der innovativen
                  {" "}<b>Digi-vCard</b> teilen Sie Ihre Kontaktdaten im Handumdrehen. Sparkassen, Genossenschaftsbanken und Unternehmen
                  nutzen bereits diese smarte Lösung, um Kunden zu gewinnen und Kontakte zu pflegen.
                </p>
                <p className="mt-5 text-base leading-6 text-black max-md:max-w-full">
                  Tauchen Sie ein in die Zukunft des Netzwerkens mit my-litfax und entdecken Sie die Innovation der digitalen
                  Visitenkarte: die{" "}<b>Digi-vCard</b>. Schnell, praktisch und effektiv teilen Sie Ihre Kontaktdaten mit Kunden, Geschäftspartnern
                  und Interessenten. Ihre physische Karte ist bereits mit Ihrem digitalen Profil verbunden – für einen reibungslosen
                  Übergang in die vernetzte Welt von morgen.
                </p>
                <p className="mt-5 text-base leading-6 text-black max-md:max-w-full">
                  Ganz gleich, ob Sie eine kleine oder große Menge benötigen – wir sind für Sie da. Kontaktieren Sie uns jetzt direkt ber
                  unseren Online-Shop oder lassen Sie sich unverbindlich von uns beraten. <a href='https://my-litfax.de/Digitale_Visitenkarten_NFC-Digitale_NFC_Visitenkarten-wg.htm'><b><ul>Mehr erfahren</ul></b></a>
                </p>
                <ul className="flex flex-col justify-center py-6 pr-111 pl-4 mt-9 rounded border border-solid border-zinc-300 max-md:pr-5 max-md:max-w-full">
                  <li className="flex gap-1.5 text-base max-md:flex-wrap">
                    <span className="font-black text-center text-lime-300 leading-[150%]">✔</span>
                    <span className="flex-auto text-black leading-[152%] max-md:max-w-full">
                      Nachhaltig netzwerken: Ihre Kontaktdaten sind immer aktuell
                    </span>
                  </li>
                  <li className="flex gap-1.5 mt-3.5 text-base max-md:flex-wrap">
                    <span className="font-black text-center text-lime-300 leading-[150%]">✔</span>
                    <span className="flex-auto text-black leading-[155%] max-md:max-w-full">
                      Einfach scannen: Funktioniert mit jedem NFC-fähigen
                      Smartphone
                    </span>
                  </li>
                  <li className="flex gap-1.5 mt-3.5 text-base max-md:flex-wrap">
                    <span className="text-base font-black leading-6 text-center text-lime-300">✔</span>
                    <span className="flex-auto text-base leading-6 text-black max-md:max-w-full">
                      Sofort startklar: Ihr vCard-Profil ist bereits mit der Karte
                      verknüpft
                    </span>
                  </li>
                </ul>
                <TabbedComponent />
              </section>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Hero01;
