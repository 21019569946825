import * as React from "react";
import { useState } from "react";

/**
 * Props for TabButton Component.
 */
interface TabButtonProps {
  className: string;
  children: React.ReactNode;
  onClick: () => void;
  isActive: boolean;
}

/**
 * Functional component for a Tab Button.
 * @param {TabButtonProps} props - Props for the component.
 * @returns {JSX.Element} The rendered component.
 */
const TabButton: React.FC<TabButtonProps> = ({
  className,
  children,
  onClick,
  isActive,
}) => {
  return (
    <button
      className={`${className} flex-grow justify-center items-center px-16 py-5 w-fit text-center max-md:px-5 ${
        isActive ? "text-white bg-blue-300" : "bg-zinc-100 text-cyan-950"
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

/**
 * Functional component for the main section.
 * @returns {JSX.Element} The rendered component.
 */
const TabbedComponent: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("Einzelbestellung");

  /**
   * Handles tab click event, sets the active tab.
   * @param {string} tab - The name of the tab that is clicked.
   */
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <section className="flex flex-col max-md:max-w-full mt-6">
      <nav className="flex gap-0 w-full text-sm whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
        <TabButton
          className="rounded border-b border-solid border-zinc-300 leading-[295%]"
          onClick={() => handleTabClick("Einzelbestellung")}
          isActive={activeTab === "Einzelbestellung"}
        >
          Einzelbestellung
        </TabButton>
        <TabButton
          className="rounded-none border-b border-solid border-zinc-300 leading-[286%]"
          onClick={() => handleTabClick("Individualanfrage")}
          isActive={activeTab === "Individualanfrage"}
        >
          Individualanfrage
        </TabButton>
      </nav>
      {activeTab === "Einzelbestellung" && (
        <div className="flex flex-col px-5 py-6 w-full bg-white rounded border border-gray-200 border-solid max-md:max-w-full">
          <article className="flex flex-col px-4 w-full max-md:max-w-full">
            <p className="text-base leading-6 text-black max-md:max-w-full">
              Für diejenigen, die ein <span className="font-medium">digitales vCard-Profil</span> mit einer oder mehreren physischen NFC-Visitenkarten kombinieren möchten.
            </p>
            <div className="flex gap-0.5 self-end mt-2.5 font-medium text-right text-cyan-950">
              <p className="text-3xl leading-6">ab 9,90 € netto</p>
              <p className="self-start text-2xl leading-6">*</p>
            </div>
          </article>
          <p className="self-center mt-3.5 text-xs leading-4 text-right text-neutral-300 max-md:max-w-full">
            (*) z. B. bei Bestellung einer Visitenkarte, inkl. vCard-Profil und zzgl. Versandkosten
          </p>
        </div>
      )}
      {activeTab === "Individualanfrage" && (
        <div className="flex flex-col px-5 py-6 w-full bg-white rounded border border-gray-200 border-solid max-md:max-w-full">
          <article className="flex flex-col px-4 w-full max-md:max-w-full">
            <p className="text-base leading-6 text-black max-md:max-w-full">
              Entscheiden Sie sich für die Individualanfrage, wenn Sie maßgeschneiderte und personalisierte Karten für
              sich oder Ihr Team in größerer Stückzahl bestellen möchten. <span className="font-medium"><a href="#">Preisbeispiele</a></span>
            </p>
            <div className="flex gap-0.5 self-end mt-2.5 font-medium text-right text-cyan-950">
              <p className="text-3xl leading-6">auf Anfrage</p>
            </div>
          </article>
          <p className="self-center mt-3.5 text-xs leading-4 text-right text-neutral-300 max-md:max-w-full">
            Wir helfen Ihnen gerne bei allen Ihren Fragen und Wünschen.
          </p>
        </div>
      )}
      <a
        href={activeTab === "Einzelbestellung" ? "https://my-litfax.de/Digitale_Visitenkarten_NFC-Digitale_NFC_Visitenkarten-wg.htm" : "https://my-litfax.de/makepage.php?p=article_detail&aid=278071"}
        className="flex gap-5 justify-end px-20 py-3.5 mt-3 text-center text-white rounded border border-solid bg-cyan-950 border-cyan-950 max-md:flex-wrap max-md:px-5 max-md:max-w-full"
      >
        <span className="flex-auto text-lg font-medium leading-6">
          {activeTab === "Einzelbestellung" ? "Digitale Visitenkarte konfigurieren" : "Individuallösung anfragen"}
        </span>
      </a>
    </section>
  );
};

export default TabbedComponent;